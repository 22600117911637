import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ApiService } from '../../Services/api.service';
import { announcement } from '../../Models/announcement';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss'],
})

export class HomeComponent implements OnInit {
    public closeResult: string;
    public loading = false;
    public announcements: announcement[];
    public announcementsLoading = false;

    constructor(
        private modalService: NgbModal,
        private apiService: ApiService
    ) {
    }

    ngOnInit() {
        this.announcementsLoading = true;
        this.apiService.getAnnouncements(5, 1, null, JSON.stringify([{ "field": "online", "direction": "DESC" }]), null).subscribe(data => {
            this.announcements = data.data;
            this.announcementsLoading = false;
        })

        if (localStorage.getItem('AccountSettings') === undefined) {
            let notPresent = true;
            Observable.create(100)
                .takeWhile(() => notPresent)
                .subscribe(() => {

                    if (localStorage.getItem('AccountSettings') === undefined) {
                        this.loading = true;
                    } else {
                        notPresent = false;
                        this.loading = false;
                    }
                });
        }
    }

    public open(content: any) {
        this.modalService.open(content, { windowClass: 'dark-modal' });
    }
}
