<title>Expertteam - Home</title>
<app-headermenu></app-headermenu>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true, primaryColour:'#63b5a2'}"></ngx-loading>

<div class="container container-home py-4">
  <div class="row">
    <div class="col-md-6">
      <div class="row mb-4">
        <!-- Bieden Card -->
        <div [routerLink]="['/bieden']" class="col-6 col-sm-4">
          <div class="card hover text-center">
            <img src="assets/images/IconBieden.png" class="card-img-top img-fluid" alt="Bieden">
            <div class="card-body">
              <p class="card-text">Bieden</p>
            </div>
          </div>
        </div>

        <!-- Mijn voertuigen Card -->
        <div [routerLink]="['/mijnvoertuigen']" class="col-6 col-sm-4">
          <div class="card hover text-center">
            <img src="assets/images/IconMijnvoertuigen.png" class="card-img-top img-fluid" alt="Mijn voertuigen">
            <div class="card-body">
              <p class="card-text">Mijn voertuigen</p>
            </div>
          </div>
        </div>

        <!-- Verkocht Card -->
        <div [routerLink]="['/verkocht']" class="col-6 col-sm-4">
          <div class="card hover text-center">
            <img src="assets/images/IconGekocht.png" class="card-img-top img-fluid" alt="Verkocht">
            <div class="card-body">
              <p class="card-text">Verkocht</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4">
        <!-- Eerder Card -->
        <div [routerLink]="['/eerder']" class="col-6 col-sm-4">
          <div class="card hover text-center">
            <img src="assets/images/IconEerder.png" class="card-img-top img-fluid" alt="Eerder">
            <div class="card-body">
              <p class="card-text">Eerder</p>
            </div>
          </div>
        </div>

        <!-- Zoeken Card -->
        <div [routerLink]="['/zoeken']" class="col-6 col-sm-4">
          <div class="card hover text-center">
            <img src="assets/images/IconZoeken.png" class="card-img-top img-fluid" alt="Zoeken">
            <div class="card-body">
              <p class="card-text">Zoeken</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nieuws Section -->
    <div class="col-md-6">
      <h3>Nieuws</h3>
      <hr>

      <!-- No Announcements -->
      <div *ngIf="announcements?.length === 0 && announcementsLoading == false">
        Er zijn geen nieuwe items beschikbaar.
      </div>

      <!-- Announcements List -->
      <div *ngIf="announcements != null && announcementsLoading == false">
        <div *ngFor="let item of announcements">
          <div><b>{{item.title}}</b></div>
          <div [innerHtml]="item.message"></div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
