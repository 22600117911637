// maintenance.interceptor.ts
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { MaintenanceService } from './maintenance.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {

  constructor(private maintenanceService: MaintenanceService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.maintenanceService.isMaintenance$.pipe(
      take(1), // it takes the current value of the maintenance mode status
      switchMap(isMaintenance => {
        if (isMaintenance) {
          // If we're in maintenance mode, block the request and return a maintenance message.
          const maintenanceMessage = "The application is under maintenance. Please try again later.";
          return throwError(() => new HttpErrorResponse({ status: 503, statusText: maintenanceMessage }));
        }
        // If not in maintenance mode, just continue the request
        return next.handle(request);
      })
    );
  }
}
