import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../Authentication/Modules/authentication.service';
import { ApiService } from '../../Services/api.service';

@Component({
    selector: 'app-headermenu',
    templateUrl: 'menu_header.component.html',
    styleUrls: ['menu_header.component.scss']
})

export class MenuHeaderComponent {
    mainmenu: Array<{ title: string, icon: string, href: string }>;
    headermenu: Array<{ title: string, icon: string, href: string }>;
    login = false;
    userData: any;
    public isCollapsed = true;

    constructor(
        private authenticationService: AuthenticationService,
        private apiService: ApiService,
        private router: Router
    ) {

        if (localStorage.getItem('authUserInformation') !== null) {
            this.userData = JSON.parse(localStorage.getItem('authUserInformation'));
        }

        this.mainmenu = [
            {title: 'Home', icon : '', href: '/home'},
            {title: 'Bieden', icon : '', href: '/bieden'},
            {title: 'Eerder', icon : '', href: '/eerder'},
            {title: 'Mijn voertuigen', icon : '', href: '/mijnvoertuigen'},
            {title: 'Verkocht', icon : '', href: '/verkocht'},
            {title: 'Zoeken', icon : '', href: '/zoeken'},
        ];

        this.headermenu = [
            { title: 'Instellingen', icon: 'fas fa-wrench', href: '/instellingen' },
            { title: 'Wachtwoord wijzigen', icon: 'fas fa-unlock-alt', href: '/wachtwoord-wijzigen' },
        ];
    }

    getCompanies() {
        // Set initial data
        this.userData = JSON.parse(localStorage.getItem('authUserInformation'));

        this.authenticationService.getAuthUserInformation().subscribe(data => {
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.userData = JSON.parse(localStorage.getItem('authUserInformation'));
        })
    }

    changeCompany() {
        this.router.navigate(['/bedrijf-selecteren']);
    }

    logout() {
        this.authenticationService.logout();
    }
}
