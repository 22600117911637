import { Component,  OnInit } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { SettingsService } from '../../Services';

@Component({
    selector: 'app-instellingen',
    templateUrl: 'instellingen.component.html',
    styleUrls: ['instellingen.component.css']
})

export class InstellingenComponent implements OnInit {
    settings: any;
    loading = false;
    settingsModel: any = {};

    saveTypes: { [k: string]: any } = {};
    emptyAccountSettings = false;
    selectOptions: { [k: string]: any } = {};
    constructor(private settingsService: SettingsService, private apiService: ApiService) {

    }
    ngOnInit() {
        this.loading = true;
        //  When this is empty we have to run the POST call for adding the settings to the account

        //  ToDo: Check for new account settings not added in the account.
        this.apiService.getAccountSetting()
            .subscribe(accountSettings => {
                this.apiService.gatherAccountSettingOptions()
                    .subscribe(accountSettingOptions => {

                        //  The account settings match with the default settings
                        if (accountSettingOptions.data.length === accountSettings.paginator.records) {
                            this.emptyAccountSettings = false;
                            //  We do have settings for this account, get the account settings and use these as default.
                            this.loading = false;
                            this.settings = accountSettings.data;

                            for (const setting of accountSettings.data) {
                                this.settingsModel[setting.id] = setting.value;
                                if (setting.SettingType.fieldType === 'select') {
                                    const str_array = setting.SettingType.fieldOptions.split(';');
                                    this.selectOptions[setting.id] = { 'options': str_array };
                                }
                                this.saveTypes[setting.id] = ({ id: setting.id, saveType: 'update' });
                            }
                        }

                        //  No account settings found.
                        if (accountSettings.paginator.records === '0') {
                            this.emptyAccountSettings = true;
                            // We have no settings for this account, using default.
                            // These are the default settings.
                            this.apiService.gatherAccountSettingOptions()
                                .subscribe(data => {
                                    this.settings = data.data;
                                    this.loading = false;
                                    for (const setting of data.data) {
                                        this.settingsModel[setting.id] = setting.defaultValue;
                                        if (setting.fieldType === 'select') {
                                            const str_array = setting.fieldOptions.split(';');
                                            this.selectOptions[setting.id] = { 'options': str_array };
                                        }

                                        this.saveTypes[setting.id] = ({ id: setting.id, saveType: 'add' });
                                    }
                                }, error => {

                                });
                        }

                        //  We do have account settings but the amount does not match
                        if (accountSettingOptions.data.length !== accountSettings.paginator.records) {
                            this.emptyAccountSettings = true;
                            // These are the default settings.
                            this.apiService.gatherAccountSettingOptions()
                                .subscribe(data => {
                                    this.settings = data.data;
                                    this.loading = false;
                                    for (const setting of data.data) {
                                        this.settingsModel[setting.id] = setting.defaultValue;
                                        if (setting.fieldType === 'select') {
                                            const str_array = setting.fieldOptions.split(';');
                                            this.selectOptions[setting.id] = { 'options': str_array };
                                        }
                                    }
                                }, error => {

                                });

                            // Now let's get the settings that exist and overwrite them :)
                            this.apiService.getAccountSetting()
                                .subscribe(accSettings => {
                                    // Loop through the data and let's replace the default value of the existing settings.
                                    for (const setting of accSettings.data) {
                                        if (setting.value !== this.settingsModel[setting.SettingType.id]) {
                                            this.settingsModel[setting.SettingType.id] = setting.value;
                                        }
                                        this.saveTypes[setting.SettingType.id] = ({ id: setting.id, saveType: 'update' });
                                    }

                                    for (const settingOptions of this.settings) {
                                        const settingfind = accSettings.data.find((e: any) => e.SettingType.id === settingOptions.id);
                                        if (!settingfind) {
                                            this.saveTypes[settingOptions.id] = ({ id: settingOptions.id, saveType: 'add' });
                                        }
                                    }
                                });
                        }
                    }, error => {

                    });
            });
    }

    UpdateApiSettings() {
        const promise = new Promise((resolve, reject) => {
            this.loading = true;
            for (const setting in this.settingsModel) {
                // We can only run this call when the settings do exist from the getAccountSettings call.

                // Check what type of save is needed
                if (this.saveTypes[setting].saveType === 'update') {
                    this.apiService.updateAccountSetting(this.settingsModel[setting], setting)
                        .subscribe(data => {
                            this.loading = false;
                        }, error => {
                            this.loading = false;
                        });
                } else if (this.saveTypes[setting].saveType === 'add') {
                    this.apiService.createAccountSetting(this.settingsModel[setting], setting)
                        .subscribe(data => {
                            this.loading = false;
                        }, error => {
                            this.loading = false;
                        });
                }
            }
            resolve(true);
        });
        return promise;
    }

    saveSettings() {
        this.UpdateApiSettings().then(() => {
            this.UpdateApiSettings();
            this.apiService.getAccountSetting()
                .subscribe(accountSettings => {
                    this.settingsService.updateSettings(accountSettings);
                });
        });
    }
}
