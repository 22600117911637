<title>Expertteam - Instellingen</title>
<app-headermenu></app-headermenu>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true,  primaryColour:'#63b5a2'}"></ngx-loading>

<div class="container container-zoeken">
  <h3>Instellingen
    <br>
  </h3>
  <hr>

  <div *ngIf="emptyAccountSettings == true">
    <div *ngFor="let setting of settings">
      <div class="form-control-sm form-group row">
        <label for="example-text-input" class="col-sm-4 col-form-label">{{setting.descriptionNl}}</label>
        <div class="col-sm-4">

          <div *ngIf="setting.fieldType == 'select'">
            <ng-select class="custom" [(ngModel)]="settingsModel[setting.id]">
              <ng-option *ngFor="let option of selectOptions[setting.id]?.options" [value]="option">{{option}}</ng-option>
            </ng-select>
          </div>

          <div *ngIf="setting.fieldType == 'text'">
            <input [(ngModel)]="settingsModel[setting.id]" [disabled]="!setting.editableByUser" value="{{setting.defaultValue}}" class="form-control-sm form-control"
              type="{{setting.fieldType}}" id="example-text-input">
          </div>

          <div *ngIf="setting.fieldType == 'numeric'">
            <input [(ngModel)]="settingsModel[setting.id]" [disabled]="!setting.editableByUser" type="numeric" value="{{setting.defaultValue}}"
              class="form-control-sm form-control" type="number" id="example-text-input">
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>

  <div *ngIf="emptyAccountSettings == false">
    <div *ngFor="let setting of settings">
      <div class="form-control-sm form-group row">
        <label for="example-text-input" class="col-sm-4 col-form-label">{{setting.SettingType.descriptionNl}}</label>
        <div class="col-sm-4">

          <div *ngIf="setting.SettingType.fieldType == 'select'">
            <ng-select class="custom" [(ngModel)]="settingsModel[setting.id]">
              <ng-option *ngFor="let option of selectOptions[setting.id].options" [value]="option">{{option}}</ng-option>
            </ng-select>
          </div>

          <div *ngIf="setting.SettingType.fieldType == 'text'">
            <input [(ngModel)]="settingsModel[setting.id]" [disabled]="!setting.SettingType.editableByUser" value="{{setting.defaultValue}}"
              class="form-control-sm form-control" type="{{setting.fieldType}}" id="example-text-input">
          </div>

          <div *ngIf="setting.SettingType.fieldType == 'numeric'">
            <input [(ngModel)]="settingsModel[setting.id]" [disabled]="!setting.SettingType.editableByUser" value="{{setting.defaultValue}}"
              class="form-control-sm form-control" type="number" id="example-text-input">
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>

  <div class="form-control-sm form-group row">
    <div class="col-sm-4">
      <button style="background-color:#d65d8c" type="button" (click)="saveSettings()" class="btn btn-primary btn-sm">Opslaan</button>
    </div>
  </div>