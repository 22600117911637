import localeNl from '@angular/common/locales/nl';
// Modules
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent, OverviewComponent, SearchDirectiveComponent } from './Directives';
import { AuthGuard } from './Authentication/Helpers/auth.guard';
import { RouteListenerComponent } from './Services/routeListener';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgSelectModule } from '@ng-select/ng-select';
//  Pipes
import { SearchPipe } from './Pipes/search.pipe';
import { LicensePipe } from './Pipes/license.pipe';
import { ReplacePipe } from './Pipes/replace.pipe';
import { CapitalizePipe } from './Pipes/capitalize.pipe';
import { DateCalPipe } from './Pipes/dateCalculator.pipe';
import { DateConverter } from './Pipes/dateconverter.pipe';
import { DateDeliveryPipe } from './Pipes/dateDelivery.pipe';
// Services
import { PagerService } from './Services/pager.service';
import { ApiService } from './Services/api.service';
import { AlertService, LocalStorageService, SettingsService, CompareService } from './Services';
//  Views
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { provideRouter, RouterModule } from '@angular/router';
import { SpinnerComponent } from './Components/spinner/spinner.component';
import { SelectCompanyComponent } from './Authentication/Components/select_company/select_company.component';
//  Header
import { AuthenticationService } from './Authentication/Modules/authentication.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './Authentication/Helpers';
import { ForgotPasswordComponent } from './Authentication/Components/forgot_password/forgot_password.component';
import { ChangePasswordComponent } from './Authentication/Components/change_password/change_password';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MaintenanceComponent } from './Components/maintenance/maintenance.component';
import { MaintenanceInterceptor } from './Authentication/Helpers/maintenance.interceptor';
import { MaintenanceService } from './Authentication/Helpers/maintenance.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { appRoutes } from './app.routing';
import { ModalService } from './Services/modal.service';
import { FooterComponent } from "./Components/footer/footer.component";
import { LoginComponent } from './Authentication/Components/login/login.component';
import { BiedingenDetailComponent } from './Components/biedingen-detail/biedingen-detail.component';
import { BiedingenComponent } from './Components/biedingen/biedingen.component';
import { EerderComponent } from './Components/eerder/eerder.component';
import { GekochtComponent } from './Components/gekocht/gekocht.component';
import { HomeComponent } from './Components/home/home.component';
import { InstellingenComponent } from './Components/instellingen/instellingen.component';
import { MenuHeaderComponent } from './Components/menu_header/menu_header.component';
import { MijnVoertuigenComponent } from './Components/mijn_voertuigen/mijn_voertuigen.component';
import { VoorwaardenComponent } from './Components/voorwaarden/voorwaarden.component';
import { WachtwoordWijzigenComponent } from './Components/wachtwoord_wijzigen/wachtwoord_wijzigen.component';
import { ZoekenComponent } from './Components/zoeken/zoeken.component';;
import { NgbCollapseModule, NgbDropdownModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker'
import { ToastrModule } from 'ngx-toastr';

// Register locale
registerLocaleData(localeNl, 'nl');

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        NoopAnimationsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce
        }),
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUiLoaderModule,
        RouterModule.forRoot(appRoutes),
        MatProgressBarModule,
        HttpClientModule,
        FooterComponent,
        NgbDropdownModule,
        NgbCollapseModule,
        NgbModule,
        NgbModalModule,
        NgxGalleryModule,
        ToastrModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    declarations: 
    [
        RouteListenerComponent,
        AppComponent,
        AlertComponent,
        OverviewComponent,
        SearchDirectiveComponent,
        BiedingenComponent,
        LoginComponent,
        HomeComponent,
        BiedingenDetailComponent,
        ZoekenComponent,
        MijnVoertuigenComponent,
        VoorwaardenComponent,
        WachtwoordWijzigenComponent,
        InstellingenComponent,
        GekochtComponent,
        EerderComponent,
        MenuHeaderComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        SearchPipe,
        LicensePipe,
        ReplacePipe,
        DateConverter,
        CapitalizePipe,
        DateCalPipe,
        DateDeliveryPipe,
        SpinnerComponent,
        SelectCompanyComponent,
        MaintenanceComponent
    ],
    providers:
     [
        OverviewComponent,
        SearchDirectiveComponent,
        AuthGuard,
        AlertService,
        AuthenticationService,
        LocalStorageService,
        SettingsService,
        CompareService,
        PagerService,
        ApiService,
        ModalService,
        MaintenanceService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MaintenanceInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})

export class AppModule {

}
