import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LocalStorageService {

    constructor(private apiservice: ApiService) {
        this.testData();
    }

clear() {
    localStorage.clear();
}

    testData() {
        if (localStorage.getItem('synced') !== 'true') {
            console.warn('Sync did not complete.');
            if (localStorage.getItem('userSession')) {
                console.warn('User is logged in and no sync has completed. Running now.');
                this.saveData();
            }
        } else {
            // console.warn('Offline sync up-to date. Last sync at: ' + this.storage.get('syncDate'))
        }
    }

    getSearchData() {
        const data = {BodyTypes: '', TransmissionTypes: '', FuelTypes: '', VehicleTypes: '', Makes: ''};

        if (localStorage.getItem('BodyTypes') === null) {
            this.saveData();
        }
        data.BodyTypes = JSON.parse(localStorage.getItem('BodyTypes'));
        data.TransmissionTypes = JSON.parse(localStorage.getItem('TransmissionTypes'));
        data.FuelTypes = JSON.parse(localStorage.getItem('FuelTypes'));
        data.VehicleTypes = JSON.parse(localStorage.getItem('VehicleTypes'));
        data.Makes = JSON.parse(localStorage.getItem('Makes'));
        return(data);
    }

    saveData() {
        this.apiservice.getBodyTypes()
            .subscribe(data => {
                localStorage.setItem('BodyTypes', JSON.stringify(data.data));
            });

        this.apiservice.getTransmissionTypes()
            .subscribe(data => {
                localStorage.setItem('TransmissionTypes', JSON.stringify(data.data));
            });

        this.apiservice.getFuelTypes()
            .subscribe(data => {
                localStorage.setItem('FuelTypes', JSON.stringify(data.data));
            });

        this.apiservice.getVehicleTypes()
            .subscribe(data => {
                localStorage.setItem('VehicleTypes', JSON.stringify(data.data));
            });

        this.apiservice.getMakes()
            .subscribe(data => {
                localStorage.setItem('Makes', JSON.stringify(data.data));
            });

            localStorage.setItem('synced', 'true');
        localStorage.setItem('syncDate', String(new Date()));
    }
}
