import { Pipe, PipeTransform } from '@angular/core';
// import { isNumeric } from '@angular/common/src/pipes/number_pipe';

@Pipe({ name: 'licensePipe' })


export class LicensePipe implements PipeTransform {

  public sidecodes: any;

  public license = '';

  transform(value: string, args: string): any {
    this.sidecodes = [];
    this.license = args;
    this.license = this.parseLicenseplate();

    this.sidecodes[0] = /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/; //  1 XX-99-99
    this.sidecodes[1] = /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/; //  2 99-99-XX
    this.sidecodes[2] = /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/; //  3 99-XX-99
    this.sidecodes[3] = /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/; //  4 XX-99-XX
    this.sidecodes[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/; //  5 XX-XX-99
    this.sidecodes[5] = /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/; //  6 99-XX-XX
    this.sidecodes[6] = /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/; //  7 99-XXX-9
    this.sidecodes[7] = /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/; //  8 9-XXX-99
    this.sidecodes[8] = /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/; //  9 XX-999-X
    this.sidecodes[9] = /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/; //  10 X-999-XX
    this.sidecodes[10] = /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/; //  11 XXX-99-X
    this.sidecodes[11] = /^[a-zA-Z]{1}[\d]{2}[a-zA-Z]{3}$/; //  12 X-99-XXX
    this.sidecodes[12] = /^[\d]{1}[a-zA-Z]{2}[\d]{3}$/; //  13 9-XX-999
    this.sidecodes[13] = /^[\d]{3}[a-zA-Z]{2}[\d]{1}$/; //  14 999-XX-9

    return this.format();
  }

  format() {
    const licenseplate = this.license;
    if (licenseplate !== undefined) {
      if (licenseplate.length === 6) {
        const sidecode = this.getSidecode();
        if (sidecode) {
          if (sidecode <= 6) {
            return `${licenseplate.substr(0, 2)}-${licenseplate.substr(2, 2)}-${licenseplate.substr(4, 2)}`;
          }
          if (sidecode === 7 || sidecode === 9) {
            return `${licenseplate.substr(0, 2)}-${licenseplate.substr(2, 3)}-${licenseplate.substr(5, 1)}`;
          }
          if (sidecode === 8 || sidecode === 10) {
            return `${licenseplate.substr(0, 1)}-${licenseplate.substr(1, 3)}-${licenseplate.substr(4, 2)}`;
          }
          if (sidecode === 11 || sidecode === 14) {
            return `${licenseplate.substr(0, 3)}-${licenseplate.substr(3, 2)}-${licenseplate.substr(5, 1)}`;
          }
          if (sidecode === 12 || sidecode === 13) {
            return `${licenseplate.substr(0, 1)}-${licenseplate.substr(1, 2)}-${licenseplate.substr(3, 3)}`;
          }
        }
      } else {
        return licenseplate;
      }
    } else {
      return licenseplate;
    }
    return licenseplate;
  }

  parseLicenseplate() {
    return this.license.replace(/-/g, '').toUpperCase();
  }

  getSidecode() {
    const licenseplate = this.parseLicenseplate();

    for (const index in this.sidecodes) {
      if (licenseplate.match(this.sidecodes[index])) {
        return Number(index) + 1;
      }
    }
    return 0;
  }
}
