<div class="d-flex justify-content-center align-items-center vh-100">
    <div class="card text-center" style="max-width: 400px; width: 100%;">
        <!-- Logo added here -->
        <img src="../../../assets/images/Logo.png" class="card-img-top mt-3 mx-auto" alt="Company Logo" style="padding-left: 20px; width: 200px; height: auto;">

        <div class="card-header">
            Onderhoudsmodus
        </div>

        <div class="card-body">
            <!-- Maintenance image -->
            <p class="card-text">
                We voeren momenteel gepland onderhoud uit. We zijn binnenkort weer online.
            </p>
        </div>

        <!-- Reload page button -->
        <div class="card-footer">
            <button type="button" class="btn btn-primary" onclick="location.reload()">Pagina herladen</button>
        </div>
    </div>
</div>
