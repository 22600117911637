import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../Modules/authentication.service';
import { authModel } from '../../Models/auth';
import { environment } from '../../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'login-app-component',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    loginTypes: any;
    error = '';
    respone: authModel;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private ngxService: NgxUiLoaderService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.checkLoginState) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit() {
        this.loginTypes = environment.level
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            remember_me: [true],
            login_type: ['', Validators.required]
        });
        this.loginForm.controls['login_type'].setValue(environment.level[0], { onlySelf: true });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.ngxService.start();
        this.loading = true;

        const deviceToken = this.authenticationService.generateDeviceToken(255);
        //Auth user with username and password.
        this.authenticationService.login(this.f['username'].value, this.f['password'].value, this.f['remember_me'].value, deviceToken, this.f['login_type'].value).subscribe(data => {
            //Check if we need to get the API key
            if (data.one_time_password === true) {
                // User used one time password. Navigate to password reset page.
                localStorage.setItem('tempPassword', 'true');
                this.router.navigateByUrl('/tijdelijk_wachtwoord_wijzigen')
            } else {
                // Get user information to show select user page (or not)
                this.authenticationService.getAuthUserInformation().subscribe(data => {
                    localStorage.setItem('authUserInformation', JSON.stringify(data));
                    if (data.Company.id !== undefined && data.Employee.id !== undefined) {
                        // Check if remember me is checked, save api key.
                        if (this.f['remember_me'].value) {
                            this.authenticationService.requestApiKey(deviceToken).subscribe(data => {
                                // Logged in with access_token and api_key
                                this.ngxService.stop();
                                this.router.navigateByUrl('/home');
                            });
                        } else {
                            // Logged in with access_token
                            this.ngxService.stop();
                            this.router.navigateByUrl('/home');
                        }
                    } else {
                        this.router.navigateByUrl('/bedrijf-selecteren');
                    }
                });
            }
        },
            error => {
                this.error = error;
                this.loading = false;
                this.ngxService.stop();
            });
    }
}
