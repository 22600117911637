import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { authModel, apiAuthModel, authStorage, authUserModel, storageApiKey } from '../Models/auth';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private authSubject: BehaviorSubject<authModel>;

    constructor(private http: HttpClient, private router: Router) {
        this.authSubject = new BehaviorSubject<authModel>(JSON.parse(localStorage.getItem('auth')));
    }

    login(username: string, password: string, remember_me: boolean, deviceToken: string, level: string) {
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('level', level)
            .append('username', username)
            .append('password', password);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post<apiAuthModel>(`${environment.api_base}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                map(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', authStorage.createapiAuth(user, remember_me, deviceToken, username, level));
                    return user;
                }));
    }

    loginWithRefreshToken(refreshToken: string): Observable<authModel> {
        const oldKey: authModel = JSON.parse(localStorage.getItem('auth'));
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .append('refresh_token', refreshToken)
            .append('username', oldKey.username)

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post<apiAuthModel>(`${environment.api_base}/api/v1/oauth/token/`, body, { headers })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const authModel = authStorage.createapiAuth(user, oldKey.save_login, oldKey.device_token, oldKey.username, oldKey.level)

                localStorage.setItem('auth', authModel);
                return JSON.parse(authModel);
            }));
    }

    loginWithApiKey(apiKey: string, username: string, deviceToken: string): Observable<authModel> {
        const oldKey: authModel = JSON.parse(localStorage.getItem('auth'));
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('username', username)
            .append('password', apiKey)
            .append('device_token', deviceToken)
            .append('level', 'key');

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post<apiAuthModel>(`${environment.api_base}/api/v1/oauth/token/`, body, { headers })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const authModel = authStorage.createapiAuth(user, oldKey.save_login, oldKey.device_token, oldKey.username, oldKey.level);
                localStorage.setItem('auth', authModel);
                return JSON.parse(authModel);
            }));
    }

    requestApiKey(deviceToken: string) {
        const body = new HttpParams()
            .set('device_token', deviceToken);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Bearer ' + this.getAuth().access_token)

        return this.http.post<any>(`${environment.api_base}/api/v1/authentication/key/`, body, { headers })
            .pipe(map(api => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('apiKey', authStorage.createapiKey(api.data));
                return api;
            }));
    }

    getAuthUserInformation() {
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', 'Bearer ' + this.getAuth().access_token)

        return this.http.get<authUserModel>(`${environment.api_base}/api/v1/authentication/`, { headers })
            .pipe(map(users => {
                return users['data'];
            }));
    }

    updateUserInformation(companyId: number, employeeId: number) {
        const body = new HttpParams()
            .set('companyId', String(companyId))
            .append('employeeId', String(employeeId))

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', 'Bearer ' + this.getAuth().access_token)

        return this.http.put<any>(`${environment.api_base}/api/v1/authentication/`, body, { headers })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return user['data'];
            }));
    }

    forgotPassword(username: string, level: string) {
        const body = new HttpParams()
            .set('username', username)
            .append('level', level)

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())

        return this.http.put<any>(`${environment.api_base}/api/v1/authentication/forgot/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }

    updateAccount(password: string, passwordCurrent: string) {
        const body = new HttpParams()
            .set('password', password)
            .append('passwordCurrent', passwordCurrent)

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')

        return this.http.put<any>(`${environment.api_base}/account/v1/account/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }

    generateDeviceToken(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    checkLoginState() {
        if (localStorage.getItem('auth') !== null) {
            const auth: authModel = JSON.parse(localStorage.getItem('auth'));
            const apiKey: storageApiKey = JSON.parse(localStorage.getItem('apiKey'));
            // Check if api key is present
            if (apiKey && apiKey.api_key !== undefined) {
                // FIX: Use moment instead of Data for support on mobile browsers
                const apiKeyExpireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date(apiKeyExpireDate) > new Date()) {
                    // Api key is valid here
                    return true;
                } else {
                    // Api key not valid
                    this.logout();
                    return false;
                }
                // No api key? Let's try the access token
            } else {
                // FIX: Use moment instead of Data for support on mobile browsers
                const refreshTokenExpireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date(refreshTokenExpireDate) > new Date()) {
                    return true;
                } else {
                    this.logout();
                    return false;
                }
            }
            // No auth data in storage? Logout user
        }
        return false
    }

    checkApiKeyValidity() {
        const apiKey: storageApiKey = JSON.parse(localStorage.getItem('apiKey'));
        if (apiKey) {
            // FIX: Use moment instead of Data for support on mobile browsers
            let expireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
            const now = new Date()
            if (expireDate > now) {
                return true;
            } else {
                return false;
            }
        } else {
            // No API key set.
            return false;
        }
    }

    checkRefreshTokenValidity() {
        const auth: authModel = JSON.parse(localStorage.getItem('auth'));
        // FIX: Use moment instead of Data for support on mobile browsers
        let expireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
        expireDate.setMinutes(expireDate.getMinutes() - 5);
        const now = new Date()
        if (expireDate > now) {
            return true;
        } else {
            return false;
        }
    }

    checkAccessTokenValidity() {
        const auth: authModel = JSON.parse(localStorage.getItem('auth'));
        // FIX: Use moment instead of Data for support on mobile browsers
        let expireDate = moment(auth.access_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
        expireDate.setMinutes(expireDate.getMinutes() - 5);
        const now = new Date()
        if (expireDate > now) {
            return true;
        } else {
            return false;
        }
    }

    getAuth(): authModel {
        return JSON.parse(localStorage.getItem('auth'));
    }

    encodeBasicHeader() {
        return ('Basic ' + btoa(environment.application + ':' + environment.password));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
