import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../Modules/authentication.service';
import { Router } from '@angular/router';
 
@Component({
     templateUrl: 'change_password.html',
     styleUrls: ['change_password.scss']
     })

export class ChangePasswordComponent {
    loading = false;
    passwordChangeForm: FormGroup;
    submitted = false;
    errorMessage = '';
    success = false;
    tempPassword = false;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.tempPassword = Boolean(localStorage.getItem('tempPassword'));
        this.passwordChangeForm = this.formBuilder.group({
            old_password: [''],
            new_password: ['', [Validators.required, Validators.minLength(8)]],
            new_confirm_password: ['', [Validators.required,  Validators.minLength(8)]],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.passwordChangeForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordChangeForm.invalid) {
            return;
        }

        this.loading = true;

        this.authenticationService.updateAccount(this.f['new_password'].value, this.f['old_password'].value).subscribe(data => {
            this.loading = false;
            if (data.error == true) {
                this.errorMessage = data.message
            } else {
                this.authenticationService.logout();
                localStorage.removeItem('tempPassword');
                this.success = true;
                this.router.navigate(['/home']);
            }
        }, error => {
            this.errorMessage = error;
            this.loading = false;
        });
    }
}
