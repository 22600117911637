import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from '../../Services/api.service';
import { LocalStorageService } from '../../Services';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-zoeken',
    templateUrl: 'zoeken.component.html',
    styleUrls: ['zoeken.component.scss'],
    animations: [
        trigger('someCoolAnimation', [
            transition('* => fadeIn', [
                style({ opacity: 0 }),
                animate(1000, style({ opacity: 1 }))
            ]),
            transition('* => fadeOut', [
                animate(1000, style({ opacity: 0 }))
            ])
        ])
    ]
})

export class ZoekenComponent implements OnInit {
    public BodyTypes: any;
    public TransmissionTypes: any;
    public FuelTypes: any;
    public VehicleTypes: any;
    public Makes: any;
    public Models: any;
    public searchForm: FormGroup;
    public isSearchingWithButton = false;

    public currentOrder = [{ field: 'id', direction: 'ASC' }];
    public setPageNumber: any;
    public SearchSettings = {
        limit: 50,
        currentPage: 1,
        filter: '',
        fields: JSON.stringify([
            "id", "VehicleSpecification-Vehicle-make", "highestExpertBid",
            "VehicleSpecification-Vehicle-modelSpecific", "BidList",
            "VehicleSpecification-Vehicle-registration", "VehicleSpecification-Vehicle-year",
            "VehicleSpecification-BodyType", "VehicleSpecification-FuelType",
            "VehicleSpecification-mileage", "VehicleSpecification-mileageType",
            "VehicleSpecification-Images", "OriginCommercialType-name",
            "OriginChannelType-name", "dateDelivery"
        ])
    };

    public pagesArray: any;
    public totalPages: number;
    public SearchResults: any;
    public TotalResults: any;
    public currentPage: number;
    public defaultFilter: any;
    public loading = false;

    constructor(
        private storage: LocalStorageService,
        private apiService: ApiService,
        private router: Router,
        private fb: FormBuilder
    ) {
        const SearchData: any = this.storage.getSearchData();

        this.BodyTypes = SearchData.BodyTypes;
        this.TransmissionTypes = SearchData.TransmissionTypes;
        this.FuelTypes = SearchData.FuelTypes;
        this.VehicleTypes = SearchData.VehicleTypes;
        this.Makes = SearchData.Makes;

        let navData: any = localStorage.getItem('idList');

        if (navData) {
            navData = JSON.parse(navData);
            if (navData.searchForm !== undefined) {
                if (navData.navigated === false && navData.lastPage === this.router.url) {
                    this.SearchSettings.filter = navData.filter;
                    this.currentPage = navData.page;
                    this.search(1);
                    localStorage.setItem('idList', JSON.stringify({ navigated: true }));
                }
            }
        }
    }

    ngOnInit(): void {
        this.searchForm = this.fb.group({
            taxnumber: [null],
            license: [null],
            vin: [null],
            vehicleType: [1],
            make: [null],
            model: [null],
            deliveryDateMin: [null],
            deliveryDateMax: [null],
            yearMin: [null],
            yearMax: [null],
            transmission: [null],
            fuelType: [null],
            bodyType: [null],
            onlyInCurrentBidTerm: [false],
            onlyMyVehicles: [false]
        });

        this.vehicleTypesUpdate(this.searchForm.get('vehicleType').value);
        this.currentPage = 1;

        this.searchForm.valueChanges.pipe(
            debounceTime(100)
        ).subscribe(value => {
            const hasPrimaryFields = value.taxnumber || value.license || value.vin;
    
            const fieldsToToggle = [
                'vehicleType', 'make', 'model', 'deliveryDateMin', 'deliveryDateMax',
                'yearMin', 'yearMax', 'transmission', 'fuelType', 'bodyType',
                'onlyInCurrentBidTerm', 'onlyMyVehicles'
            ];
    
            fieldsToToggle.forEach(field => {
                if (hasPrimaryFields) {
                    this.searchForm.controls[field].setValue(null, { emitEvent: false });
                    this.searchForm.controls[field].disable({ emitEvent: false });
                } else {
                    this.searchForm.controls['vehicleType'].setValue(1);
                    this.searchForm.controls[field].enable({ emitEvent: false });
                }
            });
        });
    }

    public search(page: any): void {
        this.loading = true;
        let navData: any = localStorage.getItem('idList');
        if (navData) {
            navData = JSON.parse(navData);
            if (navData.lastPage !== '/zoeken') {
                this.filterBuilder();
            }
        } else {
            this.filterBuilder();
        }

        this.apiService.getValuations(
            this.SearchSettings.limit, page, JSON.stringify(this.currentOrder),
            this.SearchSettings.filter, this.SearchSettings.fields, this.defaultFilter
        ).subscribe(searchResult => {
            this.SearchResults = searchResult.data;
            this.TotalResults = searchResult.paginator.records;
            this.totalPages = searchResult.paginator.pages;

            if (this.TotalResults === 1 && this.isSearchingWithButton === true) {
                this.navigateToDetail(this.SearchResults[0].id);
            } else {
                this.pagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
                this.scrollToSearchResultsElement();
            }

            this.loading = false;
        });
    }

    private filterBuilder(): void {
        this.SearchSettings.filter = '';
        const defaultFilter = [];
        const query = [];

        const searchFormValue = this.searchForm.value;
        if (searchFormValue.taxnumber !== '' || searchFormValue.license !== '') {
            for (const formItem in searchFormValue) {
                if (searchFormValue[formItem] !== '' && searchFormValue[formItem] !== null) {
                    this.addQuery(query, formItem);
                }
            }
        } else {
            for (const formItem in searchFormValue) {
                if (searchFormValue[formItem] !== '' && searchFormValue[formItem] !== null) {
                    this.addQuery(query, formItem);
                }
            }
        }
        this.defaultFilter = JSON.stringify(defaultFilter);
        this.SearchSettings.filter = JSON.stringify({ 'AND': query });
    }

    private addQuery(query: any[], formItem: string): void {
        const searchFormValue = this.searchForm.value;
        switch (formItem) {
            case 'taxnumber':
                query.push(['id', 'eq', searchFormValue.taxnumber]);
                break;
            case 'license':
                query.push(['Vehicle.registration', 'like', searchFormValue.license]);
                break;
            case 'vin':
                query.push(['Vehicle.vin', 'eq', searchFormValue.vin]);
                break;
            case 'vehicleType':
                query.push(['VehicleType.id', 'eq', searchFormValue.vehicleType]);
                break;
            case 'make':
                query.push(['Vehicle.make', 'eq', searchFormValue.make]);
                break;
            case 'model':
                query.push(['Vehicle.model', 'eq', searchFormValue.model]);
                break;
            case 'deliveryDateMin':
                query.push(['dateDelivery', 'gte', searchFormValue.deliveryDateMin]);
                break;
            case 'deliveryDateMax':
                query.push(['dateDelivery', 'lte', searchFormValue.deliveryDateMax]);
                break;
            case 'yearMin':
                query.push(['Vehicle.year', 'gte', searchFormValue.yearMin]);
                break;
            case 'yearMax':
                query.push(['Vehicle.year', 'lte', searchFormValue.yearMax]);
                break;
            case 'transmission':
                query.push(['TransmissionType.id', 'eq', searchFormValue.transmission]);
                break;
            case 'fuelType':
                query.push(['FuelType.id', 'eq', searchFormValue.fuelType]);
                break;
            case 'bodyType':
                query.push(['BodyType.name', 'eq', searchFormValue.bodyType]);
                break;
            case 'onlyInCurrentBidTerm':
                if (searchFormValue.onlyInCurrentBidTerm === true) {
                    this.defaultFilter.push('currentBidTerm');
                }
                break;
            case 'onlyMyVehicles':
                if (searchFormValue.onlyMyVehicles === true) {
                    this.defaultFilter.push('assigned');
                }
                break;
        }
    }

    public setPage(page: number): void {
        const myDiv = document.getElementById('dropdown-menu');
        myDiv.classList.remove('show');
        this.search(page);
        this.currentPage = page;
    }

    public vehicleTypesUpdate(event: any): void {
        const SearchData: any = this.storage.getSearchData();
        this.Makes = SearchData.Makes;

        if (this.Makes !== null) {
            this.Makes = this.Makes.filter(make => make.VehicleType.id === event);
        }

        this.searchForm.get('transmission').setValue(null);

        this.TransmissionTypes = SearchData.TransmissionTypes;
        if (this.TransmissionTypes !== null) {
            this.TransmissionTypes = this.TransmissionTypes.filter(transmissionType => transmissionType.VehicleType.id === event);
        }

        this.BodyTypes = SearchData.BodyTypes;

        if (this.BodyTypes !== null) {
            this.BodyTypes = this.BodyTypes.filter(bodyType => bodyType.VehicleType.id === event);
        }
    }

    public makesUpdate(event: any): void {
        const filter = `{"AND":[["VehicleType.id","eq",${this.searchForm.get('vehicleType').value}],["make","eq", "${event}"]]}`;

        this.apiService.getModels(filter).subscribe(result => {
            this.Models = result.data;
        });
    }

    private scrollToSearchResultsElement(): void {
        setTimeout(() => {
            const el = document.getElementById('result');
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
    }

    public setOrder(field: string): void {
        this.currentOrder[0].direction = this.currentOrder[0].direction === 'ASC' ? 'DESC' : 'ASC';
        this.currentOrder[0].field = field;
        this.search(this.currentPage);
    }

    public navigateToPage(): void {
        this.setPage(this.setPageNumber);
    }

    public reset() {
        if(this.searchForm.controls) {
        this.searchForm.reset();
        this.searchForm.controls['vehicleType'].setValue(1);
        this.SearchResults = null;
        }
    }

    public navigateToDetail(id: string): void {
        const routerlink = '/taxatie';
        this.loading = true;

        const saveData = {
            routerLink: routerlink,
            lastPage: this.router.url,
            navigationData: '',
            lastIndex: '',
            filter: this.SearchSettings.filter,
            page: this.currentPage,
            navigated: false,
            searchForm: this.searchForm.value
        };

        localStorage.setItem('idList', JSON.stringify(saveData));
        this.router.navigateByUrl(`${routerlink}/${id}`);
        this.loading = false;
    }
}
