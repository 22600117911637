import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apiResponse } from '../Models/apiResponse.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable()
export class ApiService {
    private api_base: string = environment.api_base;
    constructor(private http: HttpClient) { }

    getValuations(limit: any, page: any, orderBy: string, filter: any, fields: any, defaultFilter: any) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/?limit=' + limit + '&page=' +
            page + '&orderBy=' + orderBy + '&filter=' + filter + '&defaultFilter=' + defaultFilter + '&fields=' + fields, { headers: headers })
    }

    getValuationID(id: string, fields?: string) {
        const headers = new HttpHeaders();
        let params = new HttpParams()
        if (fields) { params = params.append('fields', String(fields)); }
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + id, { headers: headers, params: params });
    }

    getBodyTypes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        const orderBy = [{ 'field': 'sequence', 'direction': 'ASC' }, { 'field': 'descriptionNl', 'direction': 'ASC' }];
        return this.http.get<apiResponse>(this.api_base + '/request/v1/body-type/?limit=1000&page=1&orderBy=' + JSON.stringify(orderBy), { headers: headers })
    }

    getColorTypes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/color-type/', { headers: headers })
    }

    getFuelTypes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/fuel-type/', { headers: headers })
    }

    getVehicleTypes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-type/', { headers: headers })
    }

    getMakes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/make/?limit=1000&page=1', { headers: headers })
    }

    getModels(filter: string) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/model-specific/?limit=1000&page=1&filter=' + filter, { headers: headers })
    }

    getTransmissionTypes() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        const orderBy = [{ 'field': 'sequence', 'direction': 'ASC' }, { 'field': 'descriptionNl', 'direction': 'ASC' }];
        return this.http.get<apiResponse>(this.api_base + '/request/v1/transmission-type/?limit=1000&page=1&orderBy=' + JSON.stringify(orderBy), { headers: headers })
    }

    gatherAccountSettingOptions() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/account/v1/setting-type/', { headers: headers })
    }

    getAccountSetting() {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/account/v1/setting/?limit=25&page=1', { headers: headers })
    }

    updateAccountSetting(value: string, settingTypeId: string) {
        const headers = new HttpHeaders();
        const body = { 'value': value, 'settingTypeId': settingTypeId };
        headers.append('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/account/v1/setting/' + settingTypeId, body, { headers: headers })
    }


    createAccountSetting(value: string, settingTypeId: string) {
        const headers = new HttpHeaders();
        const body = { 'value': value, 'settingTypeId': settingTypeId };
        headers.append('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/account/v1/setting/', body, { headers: headers })
    }

    addBid(bid: string, comment: string, bidListId: string) {
        const headers = new HttpHeaders();
        const body = { 'bid': bid, 'comment': comment, 'bidListId': bidListId };
        headers.append('Accept', 'application/json');
        return this.http.post<apiResponse>(this.api_base + '/trade/v1/expert-bid/', body, { headers: headers })
    }

    addBidComment(comment: string, bidListId: string) {
        const headers = new HttpHeaders();
        const body = { 'comment': comment };
        headers.append('Accept', 'application/json');
        return this.http.put<apiResponse>(this.api_base + '/trade/v1/expert-bid/' + bidListId, body, { headers: headers })
    }

    /*-------------------------
    == COMMUNICATION CALLS ==
    ---------------------------*/
    // GET
    getAnnouncements(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/communication/v1/announcement/', { headers: RequestHeaders, params: Params });
    }

    getAnnouncementID(id: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/communication/v1/announcement/' + id, { headers: RequestHeaders });
    }
}
