import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Authentication/Helpers/auth.guard';
import { ForgotPasswordComponent } from './Authentication/Components/forgot_password/forgot_password.component';
import { ChangePasswordComponent } from './Authentication/Components/change_password/change_password';
import { SelectCompanyComponent } from './Authentication/Components/select_company/select_company.component';
import { MaintenanceComponent } from './Components/maintenance/maintenance.component';
import { LoginComponent } from './Authentication/Components/login/login.component';
import { BiedingenDetailComponent } from './Components/biedingen-detail/biedingen-detail.component';
import { BiedingenComponent } from './Components/biedingen/biedingen.component';
import { EerderComponent } from './Components/eerder/eerder.component';
import { GekochtComponent } from './Components/gekocht/gekocht.component';
import { HomeComponent } from './Components/home/home.component';
import { InstellingenComponent } from './Components/instellingen/instellingen.component';
import { MijnVoertuigenComponent } from './Components/mijn_voertuigen/mijn_voertuigen.component';
import { VoorwaardenComponent } from './Components/voorwaarden/voorwaarden.component';
import { WachtwoordWijzigenComponent } from './Components/wachtwoord_wijzigen/wachtwoord_wijzigen.component';
import { ZoekenComponent } from './Components/zoeken/zoeken.component';

export const appRoutes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'bieden', component: BiedingenComponent, canActivate: [AuthGuard] },
    { path: 'detail/taxatie/:id', component: BiedingenDetailComponent, canActivate: [AuthGuard] },
    { path: 'taxatie/:id', component: BiedingenDetailComponent, canActivate: [AuthGuard] },
    { path: 'zoeken', component: ZoekenComponent, canActivate: [AuthGuard] },
    { path: 'mijnvoertuigen', component: MijnVoertuigenComponent, canActivate: [AuthGuard] },
    { path: 'voorwaarden', component: VoorwaardenComponent, canActivate: [AuthGuard] },
    { path: 'instellingen', component: InstellingenComponent, canActivate: [AuthGuard] },
    { path: 'verkocht', component: GekochtComponent, canActivate: [AuthGuard] },
    { path: 'eerder', component: EerderComponent, canActivate: [AuthGuard] },
    { path: 'wachtwoord-wijzigen', component: WachtwoordWijzigenComponent, canActivate: [AuthGuard] },
    { path: 'bedrijf-selecteren', component: SelectCompanyComponent, canActivate: [] },
    { path: 'login', component: LoginComponent },
    { path: 'wachtwoord_vergeten', component: ForgotPasswordComponent },
    { path: 'tijdelijk_wachtwoord_wijzigen', component: ChangePasswordComponent, canActivate: [AuthGuard] },

    { path: 'maintenance', component: MaintenanceComponent },

    //  otherwise redirect to home
    { path: '**', redirectTo: 'login' },
];

