import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class SettingsService {

    constructor(private apiservice: ApiService) {
    }

    updateSettings(settings: any) {
        localStorage.setItem('AccountSettings', settings.data);
    }

    getSettingsAndSave() {
        if (localStorage.getItem('AccountSettings') === undefined) {
            this.apiservice.getAccountSetting()
            .subscribe(accountSettings => {
                if (accountSettings.paginator.records === 0) {
                    // No account settings arew foud, save the defaults
                    this.apiservice.gatherAccountSettingOptions()
                        .subscribe(accountSettingOptions => {
                            localStorage.setItem('SettingsType', 'default');
                            localStorage.setItem('AccountSettings', JSON.stringify(accountSettingOptions.data));
                        }, error => {

                        });
                } else {
                    // Account settings found, save.
                    localStorage.setItem('SettingsType', 'account');
                    localStorage.setItem('AccountSettings', JSON.stringify(accountSettings.data));
                }
            });
        } else {
            if (localStorage.getItem('SettingsType') !== undefined ) {
                this.apiservice.gatherAccountSettingOptions()
                .subscribe(accountSettingOptions => {
                    localStorage.setItem('SettingsType', 'default');
                    localStorage.setItem('AccountSettings', JSON.stringify(accountSettingOptions.data));
                }, error => {

                });
            }
        }
    }

    getSetting(settingName: string) {
        const allSettings = JSON.parse(localStorage.getItem('AccountSettings'));

        if (localStorage.getItem('SettingsType') === 'account') {
            for (const setting of allSettings) {
                if (setting.SettingType.name === settingName) {
                    return setting.value;
                }
            }
        } else if (localStorage.getItem('SettingsType') === 'default') {
            for (const setting of allSettings) {
                if (setting.name === settingName) {
                    return setting.defaultValue;
                }
            }
        }
    }
}
