<title>{{settings.HtmlTitle}}</title>
<div class="content">
    <ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true, primaryColour:'#63b5a2'}"></ngx-loading>

    <div style="display: none;" class="container search-sm">
        <div class="row">
            <div class="col-12">
                <div class="input-group">
                    <input [(ngModel)]="searchText" type="text" class="form-control form-control-sm"
                        placeholder="Taxnr, kenteken, VIN of merk">

                    <button style="color:#d65d8a;" (click)="search()" class="btn btn-sm btn-secondary" type="button">
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="container container-biedingen">
        <h3>{{settings.Title}}</h3>
        <p *ngIf="totalResults == null" class="text-muted" style="font-size:12px;">Resultaten worden geladen...</p>
        <p *ngIf="settings.EnableAmoutOfResults && totalResults != null" class="text-muted" style="font-size:12px;">Er
            zijn
            <b>{{totalResults}}</b> resultaten gevonden.
        </p>

        <div style="display: none;" class="order-sm">
            <!-- Filtering Section -->
            <div class="col-md-6 mb-2" *ngIf="settings.EnableFiltering">
                <ng-select [clearable]="false" placeholder="Soort" (change)="setFilter($event)"
                    [(ngModel)]="currentFilter.DisplayName">
                    <ng-option *ngFor="let field of settings.FilteringFields"
                        [value]="field">{{field.DisplayName}}</ng-option>
                </ng-select>
            </div>

            <!-- Button Section -->
            <div class="col-md-6 mb-2">
                <div class="d-flex align-items-center">
                    <!-- Ordering Button -->
                    <button *ngIf="settings.EnableOrdering" class="btn btn-primary btn-sm w-auto me-2">
                        <i (click)="setOrdering()" class="fa fa-sort" aria-hidden="true"></i>
                    </button>

                    <!-- List View Button -->
                    <div *ngIf="settings.EnableListView" class="w-auto me-2">
                        <button *ngIf="currentViewType == 'list'" class="btn btn-success btn-sm w-auto">
                            <i (click)="setList()" class="fas fa-list-ul" aria-hidden="true"></i>
                        </button>
                        <button *ngIf="currentViewType != 'list'" class="btn btn-secondary btn-sm w-auto">
                            <i (click)="setList()" class="fas fa-list-ul" aria-hidden="true"></i>
                        </button>
                    </div>

                    <!-- Grid View Button -->
                    <div *ngIf="settings.EnableGridView" class="w-auto">
                        <button *ngIf="currentViewType == 'grid'" class="btn btn-success btn-sm w-auto">
                            <i (click)="setGrid()" class="fas fa-th" aria-hidden="true"></i>
                        </button>
                        <button *ngIf="currentViewType != 'grid'" class="btn btn-secondary btn-sm w-auto">
                            <i (click)="setGrid()" class="fas fa-th" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="search-lg">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6" *ngIf="settings.EnableFiltering">
                            <ng-select [clearable]="false" placeholder="Soort" (change)="setFilter($event)"
                                [(ngModel)]="currentFilter.DisplayName">
                                <ng-option *ngFor="let field of settings.FilteringFields" [value]="field.RequestFilter">
                                    {{field.DisplayName}}</ng-option>
                            </ng-select>
                        </div>

                        <!-- Button Section -->
                        <div class="col-md-6 mb-2">
                            <div class="d-flex align-items-center">
                                <!-- Ordering Button -->
                                <button *ngIf="settings.EnableOrdering" class="btn btn-primary btn-sm w-auto me-2">
                                    <i (click)="setOrdering()" class="fa fa-sort" aria-hidden="true"></i>
                                </button>

                                <!-- List View Button -->
                                <div *ngIf="settings.EnableListView" class="w-auto me-2">
                                    <button *ngIf="currentViewType == 'list'" class="btn btn-success btn-sm w-auto">
                                        <i (click)="setList()" class="fas fa-list-ul" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="currentViewType != 'list'" class="btn btn-secondary btn-sm w-auto">
                                        <i (click)="setList()" class="fas fa-list-ul" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <!-- Grid View Button -->
                                <div *ngIf="settings.EnableGridView" class="w-auto">
                                    <button *ngIf="currentViewType == 'grid'" class="btn btn-success btn-sm w-auto">
                                        <i (click)="setGrid()" class="fas fa-th" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="currentViewType != 'grid'" class="btn btn-secondary btn-sm w-auto">
                                        <i (click)="setGrid()" class="fas fa-th" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6"></div>
                        <div class="col-md-6">
                            <div class="float-end">
                                <div class="input-group">
                                    <input style="width:210px;" (keyup.enter)="search()" [(ngModel)]="searchText"
                                        type="text" class="form-control-sm form-control"
                                        placeholder="Taxnr, kenteken, VIN of merk">
                                    <button style="background-color:#d65d8c" (click)="search()"
                                        class="btn btn-sm btn-secondary" type="button">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Grid View -->
        <div *ngIf="currentViewType == 'grid'" class="container">
            <div [@someCoolAnimation]="bindingVar" class="row">
                <p *ngIf="valuations == ''">
                    <i class="far fa-frown"></i> Er zijn geen resultaten gevonden.
                </p>

                <div class="col-md-4" *ngFor="let valuation of valuations" (click)="navigate(valuation.id, RouterLink)">
                    <figure class="card card-product">
                        <div class="img-wrap">
                            <div *ngIf="valuation.VehicleSpecification.Images">
                                <img defaultImage="../../../assets/images/loading.gif" [offset]="50"
                                    class="d-block img-fluid img-responsive" id="image"
                                    [lazyLoad]="valuation.VehicleSpecification.Images['0'].imageUrl" />
                            </div>
                            <div *ngIf="!valuation.VehicleSpecification.Images">
                                <img height="200" src="../../../assets/images/no-image-available_1.png">
                            </div>
                        </div>
                        <figcaption class="info-wrap">
                            <h4 class="title" style="color:#63b5a2;">
                                {{valuation.VehicleSpecification.Vehicle.make | capitalize}}
                                {{valuation.VehicleSpecification.Vehicle.modelSpecific | capitalize}}
                            </h4>
                            <hr>
                            <p class="desc" style="font-size: 13px;">
                                <b>{{valuation.VehicleSpecification.Vehicle.registration | licensePipe:
                                    valuation.VehicleSpecification.Vehicle.registration}}</b>
                                | Bouwjaar: {{valuation.VehicleSpecification.Vehicle.year}}
                                <br>
                                <i class="fas fa-gas-pump"></i>
                                <span *ngIf="valuation.VehicleSpecification.FuelType == null">Onbekend </span>
                                <span *ngIf="valuation.VehicleSpecification.FuelType != null">
                                    {{valuation.VehicleSpecification.FuelType.descriptionNl
                                    | capitalize}} </span>|
                                <i class="fas fa-tachometer-alt"></i>
                                {{valuation.VehicleSpecification.mileage | number | replace:',':'.'}}
                                {{valuation.VehicleSpecification.mileageType}}
                                <br>
                                <i class="fas fa-car"></i>
                                <span *ngIf="valuation.VehicleSpecification.BodyType == null">Onbekend</span>
                                <span *ngIf="valuation.VehicleSpecification.BodyType != null">
                                    {{valuation.VehicleSpecification.BodyType.descriptionNl
                                    | capitalize}}</span> |
                                <i class="fas fa-gavel"></i>
                                {{valuation.BidList.BidTerm.closeDateBid.date | date: 'dd-MM-yyyy'}}
                            </p>
                        </figcaption>
                        <div class="bottom-wrap">
                            <a class="btn btn-sm btn-primary float-end"
                                style="color:white !important; background-color:#d65d8a;">Bekijk deze taxatie
                                <i class="fas fa-chevron-right"></i>
                            </a>
                            <div class="price-wrap h6">
                                <div style="color:green; padding-top:5px;" *ngIf="valuation.BidList.MyBid">Uw bod:
                                    &euro;{{valuation.BidList.MyBid.bid | number:'1.0-0':'nl'}}</div>
                                <div style="color:red; font-size:11px;" *ngIf="!valuation.BidList.MyBid">Geen bieding door u
                                    uitgebracht
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>
            </div>
        </div>

        <!-- List View -->
        <div *ngIf="currentViewType == 'list'" class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th (click)="field.EnableOrdering == true && setOrdering(field.orderField)"
                            *ngFor="let field of settings.TableFields">{{field.DisplayName}}
                            <span *ngIf="field.EnableOrdering && field.orderField != orderingField "><i
                                    class="fas fa-sort"></i></span>
                            <span
                                *ngIf="field.EnableOrdering && field.orderField == orderingField && currentOrderingDirection == 'ASC'"><i
                                    class="fas fa-sort-down"></i></span>
                            <span
                                *ngIf="field.EnableOrdering && field.orderField == orderingField && currentOrderingDirection == 'DESC'"><i
                                    class="fas fa-sort-up"></i></span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <td *ngIf="valuations == ''">
                        <i class="far fa-frown"></i> Er zijn geen resultaten gevonden.
                    </td>
                    <tr *ngFor="let valuation of valuations" [@someCoolAnimation]="bindingVar"
                        (click)="navigate(valuation.id, RouterLink)">
                        <td *ngFor="let row of settings.TableFields" [ngSwitch]="row.ApiField">
                            <div *ngSwitchCase="'id'">
                                <b>T{{valuation.id}}</b>
                            </div>
                            <div *ngSwitchCase="'BidTermDate'">
                                <div
                                    *ngIf="(valuation.BidList.BidTerm.closeDateBid.date | datedifference) == 0 ">
                                    11:00
                                </div>

                                <div
                                    *ngIf="(valuation.BidList.BidTerm.closeDateBid.date | datedifference) >= 1 ">
                                    {{valuation.BidList.BidTerm.closeDateBid.date | date: 'dd-MM-yyyy' }}
                                </div>
                            </div>

                            <div *ngSwitchCase="'VehicleMakeType'">
                                {{valuation.VehicleSpecification.Vehicle.make | capitalize}}
                                {{valuation.VehicleSpecification.Vehicle.modelSpecific | capitalize}}
                                {{valuation.VehicleSpecification.type | capitalize}}</div>
                            <div *ngSwitchCase="'VehicleLicense'">{{valuation.VehicleSpecification.Vehicle.registration |
                                licensePipe:
                                valuation.VehicleSpecification.Vehicle.registration }}
                            </div>
                            <div *ngSwitchCase="'VehicleYear'">{{valuation.VehicleSpecification.Vehicle.year}}</div>
                            <div *ngSwitchCase="'VehicleFuel'">
                                <div *ngIf="valuation.VehicleSpecification.FuelType != null">
                                    {{valuation.VehicleSpecification.FuelType.descriptionNl
                                    | capitalize}}</div>
                                <div *ngIf="valuation.VehicleSpecification.FuelType == null">Onbekend</div>
                            </div>
                            <div *ngSwitchCase="'VehicleMileage'">
                                {{valuation.VehicleSpecification.mileage | number | replace:',':'.'}}</div>
                            <div *ngSwitchCase="'VehicleBodyType'">
                                <div *ngIf="valuation.VehicleSpecification.BodyType != null">
                                    {{valuation.VehicleSpecification.BodyType.descriptionNl
                                    | capitalize}}</div>
                                <div *ngIf="!valuation.VehicleSpecification.BodyType == null">Onbekend</div>
                            </div>
                            <div *ngSwitchCase="'BidMyBid'">
                                <div *ngIf="valuation.BidList.MyBid">€ {{valuation.BidList.MyBid.bid | number:'2.0-0':'nl'}}
                                </div>
                                <div *ngIf="!valuation.BidList.MyBid">-</div>
                            </div>

                            <div *ngSwitchCase="'BidHighestBid'">
                                <div *ngIf="valuation.highestExpertBid">€
                                    {{valuation.highestExpertBid | number:'2.0-0':'nl'}}</div>
                                <div *ngIf="!valuation.highestExpertBid">-</div>
                            </div>

                            <div *ngSwitchCase="'SaleFor'">
                                <div *ngIf="valuation.assignedPrice">€ {{valuation.assignedPrice | number:'2.0-0':'nl'}}</div>
                                <div *ngIf="!valuation.assignedPrice">-</div>
                            </div>

                            <div *ngSwitchCase="'BoughtOn'">
                                <div *ngIf="valuation.soldDate && valuation.soldPrice">
                                    <div>{{valuation.soldDate.date | date: 'dd-MM-yyyy'}}</div>
                                </div>

                                <div *ngIf="!valuation.soldDate && !valuation.soldPrice && valuation.assignedDate">
                                    <div>{{valuation.assignedDate.date | date: 'dd-MM-yyyy'}}</div>
                                </div>

                                <div *ngIf="!valuation.assignedDate && !valuation.soldDate ">
                                    -
                                </div>
                            </div>

                            <div *ngSwitchCase="'BoughtFor'">
                                <div *ngIf="valuation.soldDate && valuation.soldPrice">
                                    <div>€ {{valuation.soldPrice | number:'1.0-0':'nl'}}</div>
                                </div>

                                <div *ngIf="!valuation.soldDate && !valuation.soldPrice">
                                    <div>€ {{valuation.assignedPrice | number:'1.0-0':'nl'}}</div>
                                </div>
                            </div>

                            <div *ngSwitchCase="'BoughtVia'">
                                <div *ngIf="valuation.soldDate && valuation.soldPrice">
                                    <div>Te koop</div>
                                </div>

                                <div *ngIf="!valuation.soldDate && !valuation.soldPrice">
                                    <div>Bieden</div>
                                </div>
                            </div>

                            <div *ngSwitchCase="'OriginCommercialType'">{{valuation.OriginCommercialType.name | capitalize}}
                            </div>
                            <div *ngSwitchCase="'OriginChannelType'">{{valuation.OriginChannelType.name | capitalize}}</div>
                            <div *ngSwitchCase="'VehicleDeliveryDate'">
                                <div *ngIf="valuation.dateDelivery">{{(valuation | dateDelivery).date | date: 'dd-MM-yyyy'}}
                                </div>
                            </div>
                            <div *ngSwitchCase="'VehicleState'">
                                <div *ngIf="valuation.StatusType != null">{{valuation.StatusType.descriptionNl | capitalize}}
                                </div>
                                <div *ngIf="valuation.StatusType == null">Onbekend</div>
                            </div>
                            <div *ngSwitchCase="'VehicleBoughtFor'">
                                <div *ngIf="valuation.BidList.MyBid">€ {{valuation.BidList.MyBid.bid | number:'1.0-0':'nl'}}
                                </div>
                                <div *ngIf="!valuation.BidList.MyBid">-</div>
                            </div>
                            <div *ngSwitchCase="'VehicleBoughtOn'">
                                <div *ngIf="valuation.assignedDate">{{valuation.assignedDate.date | date: 'dd-MM-yyyy'}}
                                </div>
                                <div *ngIf="!valuation.assignedDate">Onbekend</div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Pagination -->
        <div *ngIf="totalPages > 1">
            <ul class="pagination pagination-sm justify-content-center">
                <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                    <a class="page-link" (click)="setPage(1)">
                        <i class="fa fa-fast-backward" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                    <a class="page-link" (click)="setPage(currentPage - 1)">
                        <i class="fa fa-backward" aria-hidden="true"></i>
                    </a>
                </li>

                <li class="page-item">
                    <div class="dropdown">
                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Pagina {{currentPage}} van {{totalPages}}
                        </button>

                        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                            <li>
                                <input (keyup.enter)="navigateToPage()" [(ngModel)]="setPageNumber" type="number"
                                    class="form-control form-control-sm" placeholder="Ga naar pagina...">
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
                    <a class="page-link" (click)="setPage(currentPage + 1)">
                        <i class="fa fa-forward" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
                    <a class="page-link" (click)="setPage(totalPages)">
                        <i class="fa fa-fast-forward" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>