import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-voorwaarden',
    templateUrl: 'voorwaarden.component.html',
    styleUrls: ['voorwaarden.component.css']
})

export class VoorwaardenComponent implements OnInit {
    ngOnInit() {
    }  
}