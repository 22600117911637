import { Component, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../Services/api.service';
import { ModalService } from '../../Services/modal.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { SettingsService } from '../../Services';
import { timer } from 'rxjs';
import { Router } from '@angular/router';
import { Valuation } from '../../Models/valuation';

@Component({
    selector: 'app-overview',
    templateUrl: 'overview.component.html',
    styleUrls: ['overview.component.scss'],
    animations: [
        trigger('someCoolAnimation', [
            transition('* => fadeIn', [
                style({ opacity: 0 }),
                animate(1000, style({ opacity: 1 }))
            ]),
            transition('* => fadeOut', [
                animate(1000, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class OverviewComponent implements OnInit, OnDestroy {
    @Input() public settings: any;

    public valuations: Valuation[];
    public pagesArray: any;
    public totalPages: number;
    public totalResults: number;
    public currentPage: number = 1;
    public RouterLink: string = '/detail/taxatie';
    public collapse: boolean = false;
    public bindingVar: string = 'fadeIn';
    public loading: boolean = false;
    public currentViewType: string;
    public currentOrderingDirection: string = '';
    public currentSearchType: any;
    public currentSearchText: any;
    public currentFilter: any;
    public alive: boolean = true;
    public maxResults: any;
    public searchText: any;
    public orderingField: string = 'id';
    private setPageNumber: any;

    constructor(
        public apiSettingsService: SettingsService,
        private modal: ModalService,
        private apiService: ApiService,
        public router: Router
    ) { }

    ngOnInit(): void {
        this.maxResults = this.apiSettingsService.getSetting(this.settings.MaxResultsSettingName);
        this.apiSettingsService.getSettingsAndSave();

        this.RouterLink = this.settings.AllowBidding ? '/detail/taxatie' : '/taxatie';

        this.currentViewType = this.settings.DefaultViewType || 'list';
        this.currentOrderingDirection = this.settings.DefaultOrdering;
        this.currentSearchType = this.settings.DefaultSearchField;

        this.initializeNavData();

        if (this.settings.RefreshInterval > 0) {
            timer(this.settings.RefreshInterval).subscribe(() => {
                if (!this.currentSearchText) {
                    this.getData(false, false, this.maxResults, this.currentPage, null, this.settings.ApiFields, this.currentFilter.RequestFilter);
                }
            });
        }

        this.getData(true, true, this.maxResults, this.currentPage, null, this.settings.ApiFields, this.currentFilter.RequestFilter);
    }

    ngOnDestroy(): void {
        this.alive = false;
    }

    public getData(showLoading: boolean, showAnimation: boolean, limit: any, page: any, filter: any, fields: any, defaultFilter: any): void {
        if (!limit) {
            limit = 25;
        }

        if (showLoading) { this.loading = true; }
        this.bindingVar = showAnimation ? 'fadeIn' : '';

        const ordering = `[{"field":"${this.orderingField}","direction":"${this.currentOrderingDirection}"}]`;
        const requestFilter = this.buildRequestFilter(filter);

        const parsedDefaultFilter = Array.isArray(defaultFilter) ? JSON.stringify(defaultFilter) : `["${defaultFilter}"]`;

        this.apiService.getValuations(limit, page, ordering, encodeURIComponent(requestFilter), JSON.stringify(fields), parsedDefaultFilter)
            .subscribe(data => {
                this.loading = false;
                this.valuations = data.data;
                this.totalPages = data.paginator.pages;
                this.totalResults = data.paginator.records;
                this.pagesArray = Array.from({ length: data.paginator.pages }, (_, i) => i + 1);
            },
                err => {
                    this.alive = false;
                    this.loading = false;
                    this.modal.showGetError(this.settings.Title);
                });
    }

    public setSearchType(value: string): void {
        this.currentSearchType.RequestFilter = value;
    }

    public navigate(taxID: string, routerLink: string): void {
        this.loading = true;

        const ordering = `[{"field":"${this.orderingField}","direction":"${this.currentOrderingDirection}"}, {"field": "id", "direction" : "${this.currentOrderingDirection}"}]`;
        const parsedDefaultFilter = Array.isArray(this.currentFilter.RequestFilter) ? JSON.stringify(this.currentFilter.RequestFilter) : `["${this.currentFilter.RequestFilter}"]`;

        this.apiService.getValuations(500, 1, ordering, '', '["id"]', parsedDefaultFilter)
            .subscribe(data => {
                const currentIndex = data.data.findIndex((item: any) => item.id === taxID);

                const saveData = {
                    routerLink: routerLink,
                    lastPage: this.router.url,
                    navigationData: data.data,
                    lastIndex: currentIndex,
                    filter: this.currentFilter,
                    page: this.currentPage,
                    navigated: false,
                    orderingField: this.orderingField,
                    orderingDirection: this.currentOrderingDirection
                };

                localStorage.setItem('idList', JSON.stringify(saveData));
                this.router.navigateByUrl(`${routerLink}/${taxID}`);
                this.loading = false;
            });
    }

    public setFilter(value: string): void {
        // Simplified and combined the conditions for better clarity
        this.updateTableFields(value);
        this.currentFilter = { DisplayName: value, RequestFilter: value };
        this.getData(true, true, this.maxResults, 1, null, this.settings.ApiFields, value);
    }

    public search(): void {
        this.currentPage = 1;
        this.currentSearchText = this.searchText;

        if (!this.searchText) {
            this.resetSearch();
        } else {
            this.performSearch();
        }
    }

    public setOrdering(field: string): void {
        this.orderingField = field;
        this.currentOrderingDirection = this.currentOrderingDirection === 'ASC' ? 'DESC' : 'ASC';
        this.getData(true, true, this.maxResults, this.currentPage, null, this.settings.ApiFields, this.currentFilter.RequestFilter);
    }

    public setPage(page: number): void {
        const myDiv = document.getElementById('dropdown-menu');
        if (myDiv) {
            myDiv.classList.remove('show');
        }

        window.scrollTo(0, 0);
        this.getData(true, true, this.maxResults, page, this.currentSearchType.RequestFilter, this.settings.ApiFields, this.currentFilter.RequestFilter);
        this.currentPage = page;
        this.setPageNumber = null;
    }

    public setList(): void {
        this.bindingVar = 'fadeIn';
        this.currentViewType = 'list';
    }

    public setGrid(): void {
        this.bindingVar = 'fadeIn';
        this.currentViewType = 'grid';
    }

    public navigateToPage(): void {
        this.collapse = true;
        this.setPage(this.setPageNumber);
    }

    private initializeNavData(): void {
        let navData: any = localStorage.getItem('idList');

        if (navData) {
            navData = JSON.parse(navData);
            if (!navData.navigated && navData.lastPage === this.router.url) {
                this.currentFilter = navData.filter;
                this.currentPage = navData.page;
                this.currentOrderingDirection = navData.orderingDirection;
                this.orderingField = navData.orderingField;
                localStorage.setItem('idList', JSON.stringify({ navigated: true }));
            } else {
                this.setDefaultFilter();
            }
        } else {
            this.setDefaultFilter();
        }
    }

    private buildRequestFilter(filter: any): string {
        if (filter === 'id' && this.currentSearchText) {
            return `{"AND":[["${filter}","eq","${this.currentSearchText}"]]}`;
        } else if (filter) {
            if (filter === 'Vehicle.registration' && this.currentSearchText.length === 6) {
                return `{"AND":[["${filter}","eq","${this.currentSearchText}"]]}`;
            } else if (filter === 'Vehicle.vin' && this.currentSearchText.length === 17) {
                return `{"AND":[["${filter}","eq","%${this.currentSearchText}%"]]}`;
            } else if (this.currentSearchText !== undefined) {
                return `{"AND":[["${filter}","like","%${this.currentSearchText}%"]]}`;
            }
        }
        return null;
    }

    private updateTableFields(value: string): void {
        if (value === 'assignedNotConfirmed') {
            this.settings.TableFields.push({ ApiField: 'BidMyBid', DisplayName: 'Mijn bod', EnableOrdering: false, orderField: '' });

            ['Gekocht op', 'Gekocht voor', 'Gekocht onder'].forEach(displayName => {
                const index = this.settings.TableFields.findIndex(x => x.DisplayName === displayName);
                if (index !== -1) {
                    this.settings.TableFields.splice(index, 1);
                }
            });

        } else if (this.settings.Title === 'Mijn voertuigen') {
            const fieldsToAdd = [
                { ApiField: 'BoughtOn', DisplayName: 'Gekocht op' },
                { ApiField: 'BoughtFor', DisplayName: 'Gekocht voor' },
                { ApiField: 'BoughtVia', DisplayName: 'Gekocht onder' }
            ];

            this.settings.TableFields = this.settings.TableFields.filter(field => field.DisplayName !== 'Mijn bod');

            fieldsToAdd.forEach(field => {
                if (this.settings.TableFields.findIndex(x => x.DisplayName === field.DisplayName) === -1) {
                    this.settings.TableFields.push({ ...field, EnableOrdering: false, orderField: '' });
                }
            });
        }
    }

    private resetSearch(): void {
        this.currentSearchType.RequestFilter = this.settings.DefaultSearchField;
        this.getData(true, true, this.maxResults, 1, null, this.settings.ApiFields, this.currentFilter.RequestFilter);
    }

    private performSearch(): void {
        const textOnly = /^[A-z ]+$/g;

        if (Number(this.currentSearchText) && this.currentSearchText.length < 15) {
            this.currentSearchType.RequestFilter = 'id';
        } else if (this.currentSearchText.length === 17) {
            this.currentSearchType.RequestFilter = 'Vehicle.vin';
        } else if (textOnly.test(this.currentSearchText)) {
            this.currentSearchType.RequestFilter = 'Vehicle.make';
        } else {
            this.currentSearchText = this.currentSearchText.replace(/\-/g, '');
            this.currentSearchType.RequestFilter = 'Vehicle.registration';
        }

        this.getData(true, true, this.maxResults, 1, this.currentSearchType.RequestFilter, this.settings.ApiFields, this.currentFilter.RequestFilter);
    }

    private setDefaultFilter(): void {
        this.currentFilter = this.settings.DefaultFilter;
        this.currentOrderingDirection = this.settings.DefaultOrdering;
        this.orderingField = 'id';
    }
}
