import { Component, OnInit } from '@angular/core';
import { Router, Event as RouterEvent } from '@angular/router';
import { MaintenanceService } from './Authentication/Helpers/maintenance.service';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
})

export class AppComponent implements OnInit {
  public showHeader: any;
  //  Sets initial value to true to show loading spinner on first load
  loading = true;

  constructor(
    private router: Router,
    private maintenanceService: MaintenanceService) {
    this.showHeader = localStorage.getItem('userSession');
    router.events.subscribe((event: RouterEvent) => {
      this.showHeader = localStorage.getItem('userSession');
    });
  }

  ngOnInit() {
    this.maintenanceService.isMaintenance$.subscribe((isMaintenance) => {
      if (isMaintenance) {
        // Redirect to maintenance page if not already there
        if (this.router.url !== '/maintenance') {
          this.router.navigate(['/maintenance']);
        }
      }
    });
  }
}
