import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {

    constructor() {
    }

    showGetError(type: any) {
        /*const dialogRef = this.modal.alert()
            .okBtn('Opnieuw proberen')
            .size('lg')
            .showClose(false)
            .title('Er is een fout opgetreden')
            .body('Er is een onverwachte fout opgetreden bij het ophalen van gegevens voor de pagina "' + type +
            '". Probeer het later opnieuw. Als dit probleem blijft voordoen neem dan contact op.')
            .open();
*/
    }

    showCompare(body: any) {
        /*
        const dialogRef = this.modal.alert()
            .okBtn('Sluiten')
            .size('lg')
            .showClose(false)
            .title('Zoek vergelijkbare')
            .body('<app-search></app-search>')
            .open();*/
    }
}
