import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
@Injectable()
export class CompareService {

    constructor() {}

    compareVehicle(data: any) {
        const type = 'gaspedaal';
        let transmission;
        let mileage;

        if (Number(data.VehicleSpecification.mileage) < 100000) {
            mileage = 120000;
        } else if (Number(data.VehicleSpecification.mileage) < 150000) {
            mileage = 180000;
        } else if (Number(data.VehicleSpecification.mileage) >= 150000) {
            mileage = 0;
        }

        // 30-01-2020 - Sentry fix: Cannot read property 'transmissionType' of null.
        if (data.VehicleSpecification.transmissionType !== null || '') {
            if (data.VehicleSpecification.TransmissionType.transmissionType === 'semi automaat'
            || data.VehicleSpecification.TransmissionType.transmissionType === 'automaat') {
               transmission = 'automaat';
           } else {
               transmission = 'handgeschakeld';
           }
        }

        switch (type) {
            case 'gaspedaal':
                const url = data.VehicleSpecification.Vehicle.make.replace(' ', '-') + '/' +
                 data.VehicleSpecification.Vehicle.model.replace(' ', '-') + '/' + transmission +
                     '/' + data.VehicleSpecification.FuelType.descriptionNl +
                    '?bmin=' + data.VehicleSpecification.Vehicle.dateFirstAdmission.date.slice(0, 4) + '&kmax=' + Number(mileage)
                     + '&srt=pr-a';
                window.open('https://www.gaspedaal.nl/' + url, '_blank');
                break;
        }
    }
}
