import { PipeTransform, Injectable, Pipe } from '@angular/core';
@Pipe({
    name: 'datedifference'
})
@Injectable()
export class DateCalPipe implements PipeTransform {
    constructor() { }
    transform(item: any, replace: any, replacement: any): any {
        if (item == null) {return ''; }

        const today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; // January is 0!
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = 0 + dd;
        }
        if (mm < 10) {
            mm = 0 + mm;
        }

        const dateToday = yyyy + '/' + mm + '/' + dd;

        const date2 = new Date(dateToday);
        const date1 = new Date(item);
        const timeDiff = Math.abs(date2.getTime() - date1.getTime());
        const dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));


        return dayDifference;
    }
}
