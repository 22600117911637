import { Component,  OnInit } from '@angular/core';
import { SettingsService } from '../../Services';

@Component({
    selector: 'app-eerder',
    templateUrl: 'eerder.component.html',
    styleUrls: ['eerder.component.css']
})

export class EerderComponent implements OnInit {
    pageSettings: any;
    constructor(public setting: SettingsService, ) {    }
    ngOnInit() {
        let viewType: any;
        if (this.setting.getSetting('show_overview_layout') === 'Foto' ) {
            viewType = 'grid';
        }
        if (this.setting.getSetting('show_overview_layout') === 'Lijst' ) {
            viewType = 'list';
        }
        this.pageSettings = ({
            // View options
            Title: 'Eerder', // The main title of the page.
            HtmlTitle: 'Expertteam - Eerder', // The name of the tab in the browser.
            DefaultViewType: viewType, // This can be set to list or grid.
            MaxResultsSettingName: 'valuations_per_page_eerder',

            AllowBidding: false, // This will enable the option to bid when navigating to detail page
            AllowNavigationOnClick: true, // This will allow the user to navigate to the detail page when row is clicked.

            EnableGridView: true, // The 'gallery' view.
            EnableListView: true, // Default table view.

            EnableOrdering: false,
            EnableFiltering: true, // Enable or disable the filering field, if disabled the default filter will be used.
            EnableSearching: true, // Enable or disable the searching field.
            EnableAmoutOfResults: true,

            RefreshInterval: 0, // Time in MS when to fetch new data, 0 for disabled.

            // These fields are used to set the search type when searching.
            SearchFields: [
            { DisplayName: 'Taxatienummer', RequestFilter: 'closedBidTerm' },
            { DisplayName: 'Biedtermijn', RequestFilter: 'closedBidTerm' },
            { DisplayName: 'Voertuig', RequestFilter: 'closedBidTerm' },
            { DisplayName: 'Kenteken', RequestFilter: 'closedBidTerm' },
            { DisplayName: 'Bouwjaar', RequestFilter: 'closedBidTerm' },
            { DisplayName: 'Carrosserie', RequestFilter: 'closedBidTerm' }],

            // These fields are used to fill the table with some data.
            TableFields: [
            {ApiField: 'id', DisplayName: 'Taxnr', EnableOrdering: true, orderField: 'id'},
            {ApiField: 'VehicleLicense', DisplayName: 'Kenteken', EnableOrdering: false, orderField: ''},
            {ApiField: 'VehicleMakeType', DisplayName: 'Voertuig', EnableOrdering: true, orderField: 'Vehicle.make'},
            {ApiField: 'VehicleYear', DisplayName: 'Jaar',  EnableOrdering: true, orderField: 'Vehicle.year'},
            {ApiField: 'VehicleDeliveryDate', DisplayName: 'Leverdatum', EnableOrdering: true, orderField: 'dateDelivery'},
            {ApiField: 'BidMyBid', DisplayName: 'Mijn bod', EnableOrdering: false, orderField: ''},
            {ApiField: 'BidHighestBid', DisplayName: 'Hoogste bod', EnableOrdering: false, orderField: ''}],

            // These fields are used to set the filter types.
            FilteringFields: [
            { DisplayName: 'Hoogste bod', RequestFilter: ['closedBidTerm', 'highestBid'] },
            { DisplayName: 'Wel geboden', RequestFilter:  ['closedBidTerm', 'withBid'] },
            { DisplayName: 'Niet geboden', RequestFilter: ['closedBidTerm', 'withoutBid'] },
            { DisplayName: 'Totaal', RequestFilter: 'closedBidTerm' }],

            // API fields
            DefaultOrdering: 'DESC', // This can be set to ASC and DESC and will affect the view's ordering.
            DefaultFilter: { DisplayName: 'Hoogste bod', RequestFilter: ['closedBidTerm', 'highestBid']},
            DefaultSearchField: { DisplayName: 'Taxatienummer', RequestFilter: 'id' },
            ApiFields: ["id", "highestExpertBid", "VehicleSpecification-Vehicle-make", "VehicleSpecification-Vehicle-modelSpecific",
            "BidList", "VehicleSpecification-Vehicle-registration", "VehicleSpecification-Vehicle-year",
            "VehicleSpecification-BodyType", "VehicleSpecification-FuelType", "VehicleSpecification-mileage",
             "VehicleSpecification-Images", "dateDelivery", "VehicleSpecification-type", "VehicleSpecification-mileageType"]
        });
    }
}
