import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, transition, style, animate } from '@angular/animations';
import { ApiService } from '../../Services/api.service';
import { LocalStorageService } from '../../Services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search',
    templateUrl: 'search.directive.html',
    styleUrls: ['search.directive.scss'],
    animations: [
        trigger('someCoolAnimation', [
            transition('* => fadeIn', [
                style({ opacity: 0 }),
                animate(1000, style({ opacity: 1 }))
            ]),
            transition('* => fadeOut', [
                animate(1000, style({ opacity: 0 }))
            ])
        ])
    ]
})

export class SearchDirectiveComponent implements OnInit {
    @Input() settings: any;
    // These fields are used for filling the advanced search form.
    BodyTypes: any; TransmissionTypes: any; FuelTypes: any; VehicleTypes: any; Makes: any; Models: any;
    searchForm: any = {};

    currentOrder = [{ field: 'id', direction: 'ASC' }];
    setPageNumber: any;
    SearchSettings = {
        limit: 50,
        currentPage: 1,
        filter: '',
        fields: `["id", "VehicleSpecification-Vehicle-make", "VehicleSpecification-Vehicle-modelSpecific",
        "BidList", "VehicleSpecification-Vehicle-registration", "VehicleSpecification-Vehicle-year",
        "VehicleSpecification-BodyType", "VehicleSpecification-FuelType", "VehicleSpecification-mileage",
         "VehicleSpecification-Images", "OriginCommercialType-name", "OriginChannelType-name"]`
    };

    pagesArray: any;
    totalPages: number;
    SearchResults: any;
    TotalResults: any;
    currentPage: number;
    defaultFilter: any;
    loading = false;

    bindingVar = '';
    fadeIn() {
        this.bindingVar = 'fadeIn';
    }

    constructor(
        private storage: LocalStorageService,
        private router: Router,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private apiService: ApiService
    ) {
        this.fadeIn();
        // Get the basic data from the browser's memory
        const SearchData: any = this.storage.getSearchData();

        this.BodyTypes = SearchData.BodyTypes;
        this.TransmissionTypes = SearchData.TransmissionTypes;
        this.FuelTypes = SearchData.FuelTypes;
        this.VehicleTypes = SearchData.VehicleTypes;
        this.Makes = SearchData.Makes;
    }

    ngOnInit() {
        if (this.settings.VehicleSpecification.VehicleType.id != null) {
            this.searchForm.vehicleType = this.settings.VehicleSpecification.VehicleType.id;
        } else {
            this.searchForm.vehicleType = 1;
        }
        this.vehicleTypesUpdate(this.searchForm.vehicleType);
        this.currentPage = 1;
        this.searchForm.make = this.settings.VehicleSpecification.Vehicle.make;
        this.searchForm.model = this.settings.VehicleSpecification.Vehicle.model;
        if (this.settings.VehicleSpecification.FuelType != null) {
            this.searchForm.fuelType = this.settings.VehicleSpecification.FuelType.id;
        }
        this.searchForm.yearMin = this.settings.VehicleSpecification.Vehicle.year;

        this.search(1);
    }

    search(page: any) {
        this.loading = true;
        this.filterBuilder();
        return this.apiService.getValuations(this.SearchSettings.limit, page,
            JSON.stringify(this.currentOrder), this.SearchSettings.filter, this.SearchSettings.fields, this.defaultFilter)
            .subscribe(searchResult => {
                this.SearchResults = searchResult.data;
                this.TotalResults = searchResult.paginator.records;
                this.totalPages = searchResult.paginator.pages;
                // Put pages inside array for view
                const items: number[] = [];
                for (let i = 1; i <= searchResult.paginator.pages; i++) {
                    items.push(i);
                }
                this.pagesArray = items;
                this.loading = false;
            });
    }

    filterBuilder() {
        // This function will check what sype of search the user is requesting and will set the right query to run.
        this.SearchSettings.filter = '';
        const defaultFilter = [];
        const query = [];
        for (let formItem in this.searchForm) {
            if (this.searchForm[formItem] !== '') {

                switch (formItem) {
                    case 'taxnumber': {
                        query.push(['id', 'like', '%' + this.searchForm.taxnumber + '%']);
                        break;
                    }

                    case 'license': {
                        query.push(['Vehicle.registration', 'like', this.searchForm.registration]);
                        break;
                    }


                    case 'vehicleType': {
                        query.push(['VehicleType.id', 'eq', this.searchForm.vehicleType]);
                        break;
                    }

                    case 'make': {
                        query.push(['Vehicle.make', 'eq', this.searchForm.make]);
                        break;
                    }

                    case 'model': {
                        query.push(['Vehicle.model', 'eq', this.searchForm.model]);
                        break;
                    }

                    case 'deliveryDateMin': {
                        query.push(['dateDelivery', 'gte', this.searchForm.deliveryDateMin]);
                        break;
                    }

                    case formItem = 'deliveryDateMax': {
                        query.push(['dateDelivery', 'lte', this.searchForm.deliveryDateMax]);
                        break;
                    }

                    case formItem = 'yearMin': {
                        query.push(['Vehicle.year', 'gte', this.searchForm.yearMin]);
                        break;
                    }

                    case formItem = 'yearMax': {
                        query.push(['Vehicle.year', 'lte', this.searchForm.yearMax]);
                        break;
                    }

                    case formItem = 'transmission': {
                        query.push(['TransmissionType.id', 'eq', this.searchForm.transmission]);
                        break;
                    }
                    case formItem = 'fuelType': {
                        query.push(['FuelType.id', 'eq', this.searchForm.fuelType]);
                        break;
                    }
                    case formItem = 'bodyType': {
                        query.push(['BodyType.id', 'eq', this.searchForm.bodyType]);
                        break;
                    }
                    case formItem = 'onlyInCurrentBidTerm': {
                        if (this.searchForm.onlyInCurrentBidTerm === true) {
                            defaultFilter.push('currentBidTerm');
                        }
                        break;
                    }
                    case formItem = 'onlyMyVehicles': {
                        if (this.searchForm.onlyMyVehicles === true) {
                            defaultFilter.push('assigned');
                        }
                        break;
                    }
                }
            }
        }
        this.defaultFilter = JSON.stringify(defaultFilter);
        this.SearchSettings.filter = JSON.stringify({ 'AND': query });
    }

    setPage(page: number) {
        const myDiv = document.getElementById('dropdown-menu');
        myDiv.classList.remove('show');

        this.search(page);
        this.currentPage = page;
    }

    vehicleTypesUpdate(event: any) {
        const SearchData: any = this.storage.getSearchData();
        this.Makes = SearchData.Makes;
        this.Makes = (this.Makes.filter(make => make.VehicleType.id === event));
    }

    makesUpdate(event: any) {
        const filter = '{"AND":[["VehicleType.id","eq",' + this.searchForm.vehicleType + '],["make","eq", "' + event + '"]]}';

        return this.apiService.getModels(filter)
            .subscribe(result => {
                this.Models = result.data;
            });
    }

    setOrder(field: string) {
        if (this.currentOrder[0].direction === 'ASC') {
            this.currentOrder[0].direction = 'DESC';
        } else {
            this.currentOrder[0].direction = 'ASC';
        }

        this.currentOrder[0].field = field;
        this.search(this.currentPage);
    }

    navigateToPage() {
        this.setPage(this.setPageNumber);
    }

    navigateToTax(id) {
        const element: HTMLElement = document.getElementById('btn') as HTMLElement;
        element.click();
        this.router.navigateByUrl('/taxatie/' + id);
    }
}
