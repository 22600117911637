import { Component, OnInit, NgZone } from '@angular/core';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ModalService } from '../../Services/modal.service';
import { CompareService } from '../../Services';
import { Subject } from 'rxjs';
import { GalleryItem } from '@ngx-gallery/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { SearchDirectiveComponent } from '../../Directives';

@Component({
    selector: 'app-biedingen-detail',
    templateUrl: 'biedingen-detail.component.html',
    styleUrls: ['biedingen-detail.component.scss']
})

export class BiedingenDetailComponent implements OnInit {
    galleryOptions: NgxGalleryOptions[];
    galleryImages = [];

    state: boolean;
    images: GalleryItem[] = [];
    _damageAlbum: any[] = [];
    allItems: any;
    closeResult: string;
    bidsDisabled: boolean;
    showBidError = false;
    showNoBidMessage = false;
    newBid: any = {};
    taxID: string;

    public carouselSubject = new Subject<any>();
    public onlyComments = true;
    public loading: boolean;

    formData: any = {};
    showSearch: any;
    BodyTypes: any;
    TransmissionTypes: any;
    FuelTypes: any;

    pagesArray: any;
    totalPages: number;
    SearchResults: any;
    TotalResults: any;
    currentPage: number;

    showModal = false;
    nextTaxDisabled: boolean;

    SearchSettings = {
        limit: 50,
        currentPage: 1,
        ordering: '[{"field":"id","direction":"ASC"}]',
        filter: '',
        fields: ['id', 'VehicleSpecification-Vehicle-make', 'BidList', 'VehicleSpecification-Vehicle-registration',
            'VehicleSpecification-Vehicle-year', 'VehicleSpecification-BodyType', 'VehicleSpecification-FuelType',
            'VehicleSpecification-mileage', 'dateDelivery', 'StatusType-descriptionNl', 'assignedDate',
            'OriginCommercialType-name', 'OriginChannelType-name', 'selfRequested']
    };

    constructor(
        private compareService: CompareService,
        private modal: ModalService,
        private router: Router,
        private ngZone: NgZone,
        private apiService: ApiService,
        private modalService: NgbModal,
        private _location: Location,
        private route: ActivatedRoute) {
    }

    getTaxDetails() {
        // Fix for navigating to next tax.
        if (this.taxID === undefined) {
            let taxID = this.route.snapshot.paramMap.get('id')
            if (Number(taxID)) {
                this.taxID = this.route.snapshot.paramMap.get('id')
                this.getData(taxID, true);
                this.bidsDisabled = false;
            } else {
                this.taxID = this.route.snapshot.paramMap.get('id')
                this.getData(taxID, true);
                this.bidsDisabled = false;
            }
        } else {
            this.getData(this.taxID, true);
        }
    }

    ngOnInit() {
        this.galleryOptions = [
            {
                thumbnailsColumns: 4,
                previewAnimation: false,
                previewDownload: true,
                previewArrows: true,
                previewCloseOnEsc: true,
                previewFullscreen: true,
                previewRotate: true,
                previewBullets: true,
                previewKeyboardNavigation: true,
                previewCloseOnClick: true,
                previewZoom: true,
                previewZoomStep: 0.4,
                previewZoomMax: 5,
                previewDescription: true,
                imageDescription: true,
                imageAnimation: 'none',
            }
        ];

        this.nextTaxDisabled = false;
        this.loading = true;
        // Start checking for screen sizes on init
        this.checkForResize();
        this.getTaxDetails();
    }

    getData(id: string, loading: boolean) {
        if (loading) this.loading = true;

        this.images = [];
        this.newBid.bidComment = '';
        this.images.splice(0, this.images.length);
        return this.apiService.getValuationID(id).subscribe(data => {
            this.allItems = data.data;
            // Save images in array to show
            for (const key in this.allItems.VehicleSpecification.Images) {
                const src = this.allItems.VehicleSpecification.Images[key].imageUrl;
                const thumb = this.allItems.VehicleSpecification.Images[key].thumbnailUrl;
                this.galleryImages.push({
                    small: thumb,
                    medium: src,
                    big: src
                });
            }

            this.allItems = data.data;
            // Save images in array to show
            let index = 0;
            if (this.allItems.VehicleSpecification.DamageSpecification) {
                for (const key in this.allItems.VehicleSpecification.DamageSpecification.Damages) {
                    if (this.allItems.VehicleSpecification.DamageSpecification.Damages[key].image !== null) {
                        index++;
                        const damageDescriptions = 'Schade: ' + index + ' - onderdeel: ' + this.allItems.VehicleSpecification.DamageSpecification.Damages[key].PartType.descriptionNl;
                        this.galleryImages.push({
                            small: this.allItems.VehicleSpecification.DamageSpecification.Damages[key].thumbnail,
                            medium: this.allItems.VehicleSpecification.DamageSpecification.Damages[key].image,
                            big: this.allItems.VehicleSpecification.DamageSpecification.Damages[key].image,
                            label: 'Schadefoto',
                            description: damageDescriptions
                        });
                    }
                }
            }

            if (this.allItems.BidList.MyBid) {
                if (this.allItems.BidList.MyBid.comment !== null) {
                    this.newBid.bidComment = this.allItems.BidList.MyBid.comment;
                }
            }

            if (this.allItems.BidList.BidTerm.open === true) {
                this.onlyComments = false;
            } else {
                if (this.allItems.BidList.MyBid != null) {
                    this.onlyComments = true;
                } else {
                    this.bidsDisabled = true;
                }
            }
            this.loading = false;
        },
            err => {
                this.modal.showGetError('taxatie');
            });
    }

    checkForResize() {
        // This will run on every screen size change
        window.onresize = (e) => {
            this.ngZone.run(() => {
                if (window.innerWidth < 700) {
                    this.state = true;
                } else {
                    this.state = false;
                }
            });
        };
    }

    openModal(content: any) {
        this.modalService.open(content).result.then((result) => {
            this._location.back();
        }, (reason) => {
            this._location.back();
        });
    }

    print(): void {
        let printContents, popupWin;
        printContents = document.getElementById('print').innerHTML;
        popupWin = window.open('', '_blank', '');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Printweergave taxatie</title>
              <link href='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css' rel='stylesheet'
              integrity='sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO' crossorigin='anonymous'>
            </head>
            <body onload='window.print()'>
            <style>
            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
            </style>
            ${printContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

    email() {
        let printContents, popupWin;
        printContents = document.getElementById('print').innerHTML;
        popupWin = window.open('', '_blank', '');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Email voorbeeld</title>
              <link href='https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css' rel='stylesheet'
              integrity='sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO' crossorigin='anonymous'>
            </head>
            <body onload='window.print()'>
            <style>
            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
            </style>
            ${printContents}</body>
          </html>`
        );
    }

    search() {
        return this.apiService.getValuations(this.SearchSettings.limit, 1, this.SearchSettings.ordering,
            this.SearchSettings.filter, this.SearchSettings.fields, '')
            .subscribe(searchResult => {
                this.SearchResults = searchResult.data;
                this.TotalResults = searchResult.paginator.records;
                this.totalPages = searchResult.paginator.pages;
                // Put pages inside array for view
                const items: number[] = [];
                for (let i = 1; i <= searchResult.paginator.pages; i++) {
                    items.push(i);
                }
                this.pagesArray = items;
                this.showSearch = true;
            });
    }

    addBid(bidListId: string, nextBid) {
        if (this.newBid.bidAmount != null) {
            this.loading = true;
            return this.apiService.addBid(this.newBid.bidAmount, this.newBid.bidComment, bidListId)
                .subscribe(data => {
                    this.loading = false;
                    if (data.error == false) {
                        this.showBidError = false;
                        this.showNoBidMessage = false;
                        if (nextBid === true) {
                            this.newBid.bidAmount = '';
                            this.newBid.bidComment = '';
                            this.navNext();
                        } else {
                            // Reload taxation data.
                            this.showBidError = false;
                            this.showNoBidMessage = false;
                            this.newBid = {};
                            this.getTaxDetails();
                        }
                    } else {
                        this.showBidError = true;
                    }
                });
        } else {
            this.showNoBidMessage = true;
        }
        return null;
    }

    addComment(bidListId: string) {
        this.loading = true;
        return this.apiService.addBidComment(this.newBid.bidComment, bidListId)
            .subscribe(data => {
                this.getTaxDetails();
                this.images = [];
                this._damageAlbum = [];
                this.loading = false;
            });
    }

    compare() {
        this.compareService.compareVehicle(this.allItems);
    }

    navPrevPage() {
        const navData = JSON.parse(localStorage.getItem('idList'));
        const page = navData.lastPage;
        this.ngZone.run(() => this.router.navigateByUrl(page));
    }

    navNext() {
        this.galleryImages = [];
        this._damageAlbum = [];
        this.newBid.bidComment = '';

        const navData = JSON.parse(localStorage.getItem('idList'));

        let currentIndex = Number(navData.lastIndex);
        const nextIndex = (currentIndex += 1);

        navData.lastIndex = JSON.stringify(nextIndex);
        localStorage.setItem('idList', JSON.stringify(navData));

        this.taxID = navData.navigationData[nextIndex].id;
        this.nextTaxDisabled = false;
        // This will unset the search modal.
        this.showSearch = false;
        this.allItems = null;
        this.ngZone.run(() => this.router.navigateByUrl(navData.routerLink + '/' + navData.navigationData[nextIndex].id));
        this.getData(navData.navigationData[nextIndex].id, true);

        // Disable the next button.
        if (navData.navigationData.length > nextIndex) {
            this.nextTaxDisabled = false;
        } else {
            this.nextTaxDisabled = true;
        }
    }

    scrollToBidElement(id: any) {
        const el = document.getElementById(id);
        el.scrollIntoView({ behavior: 'smooth' });
    }

    searchVehicles() {
        // Open the VehicleSearchModalComponent as a modal
        const modalRef = this.modalService.open(SearchDirectiveComponent, {
            size: 'xl',
            backdrop: 'static',
        });

        // Pass data to the modal component
        modalRef.componentInstance.settings = this.allItems;
    }

    checkForDecimals() {
        const old = String(this.newBid.bidAmount);
        this.newBid.bidAmount = old.replace(/\,/g, '');
        this.newBid.bidAmount = old.replace(/\./g, '');
    }
}
