<title>Autotaxatie Partners | Expertteam - login</title>

<ngx-ui-loader fgsColor="#d65d8c"></ngx-ui-loader>

<div class="login-container">
    <div class="container">
        <div class="px-4">

            <img src="../../../assets/images/logo-text.png" class="header-image img-fluid mb-4" alt="ATP logo">

            <div class="card">
                <h4 class="card-header">Expertteam Inloggen</h4>
                <div class="card-body">
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">Er is een fout opgetreden. Controleer je
                        gegevens.</div><br>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                        <div *ngIf="loginTypes.length > 1" class="mb-3">
                            <label for="username" class="form-label">Soort login</label>
                            <select formControlName="login_type" class="form-select">
                                <option [selected]="true" value="user">Gebruiker</option>
                                <option value="person">Persoon</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="username" class="form-label">Gebruikersnaam</label>
                            <input type="text" formControlName="username" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
                            <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">Gebruikersnaam is verplicht</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Wachtwoord</label>
                            <input type="password" formControlName="password" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Wachtwoord is verplicht</div>
                            </div>
                        </div>

                        <div class="form-check mb-3">
                            <input type="checkbox" formControlName="remember_me" class="form-check-input">
                            <label class="form-check-label" for="remember_me">Onthouden</label>
                        </div>

                        <div class="mb-3">
                            <a routerLink="/wachtwoord_vergeten">Wachtwoord vergeten?</a>
                        </div>

                        <button [disabled]="loading" class="btn btn-primary w-100 login-button">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm me-2"></span>
                            Inloggen
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
