<app-headermenu [hidden]="tempPassword"></app-headermenu>
<title>Expertteam - Wachtwoord wijzigen</title>
<div class="container  container-wachtwoord-wijzigen">
  <h3>Wachtwoord wijzigen
    <br>
  </h3>
  <hr>

  <div class="row">
    <div class="col-sm-6">
      <form name="form" (ngSubmit)="f.form.valid && updatePassword()" #f="ngForm" novalidate>

        <div class="alert alert-danger" *ngIf="error" role="alert">
          Er is een fout opgetreden bij het wijzigigen van uw wachtwoord. Probeer het opnieuw.
        </div>

        <div class="alert alert-warning" *ngIf="tempPassword" role="alert">
          Omdat u momenteel een tijdelijk wachtwoord gebruikt moet u uw wachtwoord wijzigen.
        </div>

        <div class="alert alert-danger" *ngIf="response == 'Incorrect current password'" role="alert">
          Het opgegeven huidige wachtwoord is onjuist.
        </div>

        <div *ngIf="fullResponse.password">
          <div class="alert alert-warning" *ngIf="fullResponse.password.regexNotMatch != ''" role="alert">
            Het opgegeven nieuwe wachtwoord is niet sterk genoeg! Zorg ervoor dat het wachtwoord aan de bovenstaande criteria voldoet.
          </div>
        </div>
        
        <div *ngIf="!tempPassword" class="form-group" [ngClass]="{ 'has-error': f.submitted && !currentPassword.valid || response == 'Incorrect current password' }">
          <div *ngIf="f.submitted && !currentPassword.valid" class="help-block has-error">Huidig wachtwoord is verplicht</div>
          <div class="input-group">
            <input placeholder="Huidig wachtwoord" type="password" class="form-control" name="currentPassword" [(ngModel)]="account.currentPassword"
              #currentPassword="ngModel" required />
          </div>
        </div>

        <br>

        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password1.valid }">
          <div *ngIf="f.submitted && !password1.valid" class="help-block has-error">Wachtwoord is verplicht</div>
          <div class="input-group">
            <input placeholder="Voer een nieuw wachtwoord in" type="password" class="form-control" name="password1" [(ngModel)]="account.password1"
              #password1="ngModel" required />
          </div>
        </div>

        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password2.valid }">
          <div *ngIf="f.submitted && !password2.valid" class="help-block has-error">Wachtwoord is verplicht</div>
          <div class="input-group">
            <input placeholder="Herhaal wachtwoord" type="password" class="form-control" name="password2" [(ngModel)]="account.password2"
              #password2="ngModel" required />
          </div>
        </div>

        <div class="form-group">
          <button style="background-color:#d65d8c" [disabled]="account.password1 != account.password2" class="form-control" class="btn btn-primary btn-sm">Opslaan</button>
        </div>

      </form>
    </div>

    <div class="col-sm-6">
      <p>Het nieuwe wachtwoord moet voldoen aan de onderstaande criteria:</p>
      <ul>
        <li>Minimaal één cijfer bevatten</li>
        <li>Minimaal één kleine letter bevatten</li>
        <li>Minimaal één hoofdletter bevatten</li>
        <li>Minimaal één symbool ((!&#64;$%^&*()
          <>,.?/[]-=_+)) bevatten </li>
      </ul>
    </div>
  </div>
</div>