import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, finalize, shareReplay, switchMap, take } from "rxjs/operators";
import { AuthenticationService } from "../Modules/authentication.service";
import { authModel, storageApiKey } from "../Models/auth";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private isTokenRequesting = false;
    private tokenSubject: BehaviorSubject<authModel | null> = new BehaviorSubject<authModel | null>(null);

    constructor(public authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Define a regular expression to match URLs containing oauth, authentication, or ipify
        const re = /oauth|authentication|ipify/gi;

        // Check if we want to bypass authentication
        if (request.url.search(re) !== -1) {
            return next.handle(request);
        }

        const currentUser = JSON.parse(localStorage.getItem("auth")!);
        if (!currentUser) {
            // If there is no current user, log out
            this.doLogout();
            return throwError(() => new HttpErrorResponse({ status: 401 }));
        } else {
            // Check if the access token is valid
            if (!this.authenticationService.checkAccessTokenValidity()) {
                // If the access token is invalid or the isRefreshing flag is true, handle the unauthorized request
                return this.handleUnauthorized(request, next);
            } else {
                // If the access token is valid, add the authorization header to the request
                const tokenReq = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.access_token}`,
                    },
                });

                return next.handle(tokenReq).pipe(
                    catchError((err: HttpErrorResponse) => {
                        // If the error is specifically a 401 error due to an IP change, handle it differently
                        if (err.status === 401 && err.error.title === "invalid_token_ip") {
                            // The logic for handling "invalid_token_ip" error stays the same as before
                            if (this.isRefreshing) {
                                return this.tokenSubject.pipe(
                                    filter(result => result !== null),
                                    take(1),
                                    switchMap(() => next.handle(this.addTokenToRequest(request, this.tokenSubject.value!.access_token)))
                                );
                            } else {
                                if (this.authenticationService.checkApiKeyValidity()) {
                                    this.isRefreshing = true;
                                    this.tokenSubject.next(null);
                                    this.isTokenRequesting = true;

                                    const apiKey: storageApiKey = JSON.parse(localStorage.getItem("apiKey")!);
                                    return this.loginWithApiKeyAndRetry(apiKey.api_key, currentUser, request, next).pipe(
                                        finalize(() => {
                                            this.isTokenRequesting = false;
                                        })
                                    );
                                }
                            }
                        }

                        // If the access token is invalid, attempt to refresh it using the refresh token
                        if (!this.authenticationService.checkAccessTokenValidity() && this.authenticationService.checkRefreshTokenValidity()) {
                            return this.refreshTokenAndRetry(currentUser.refresh_token, request, next);
                        }

                        // For all other errors or if the refresh token is not valid, handle it as unauthorized
                        return this.handleUnauthorized(request, next);
                    })
                );
            }
        }
    }

    private handleUnauthorized(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isRefreshing || this.isTokenRequesting) {
            // If a token refresh process or token requesting is already ongoing, wait for it to finish
            return this.tokenSubject.pipe(
                filter((result) => result !== null),
                take(1),
                switchMap((newAuthModel) => {
                    const newTokenReq = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${newAuthModel?.access_token}`,
                        },
                    });
                    return next.handle(newTokenReq);
                })
            );
        } else {
            const currentUser: authModel | null = JSON.parse(localStorage.getItem("auth")!);

            if (currentUser) {
                // If the refresh token is valid
                if (this.authenticationService.checkRefreshTokenValidity()) {
                    // Refresh the token and retry the request
                    this.isRefreshing = true;
                    return this.refreshTokenAndRetry(currentUser.refresh_token, request, next);
                }
                // If the API key is valid
                else if (this.authenticationService.checkApiKeyValidity()) {
                    const apiKey: storageApiKey = JSON.parse(localStorage.getItem("apiKey")!);
                    // Login with API key and retry the request
                    this.isRefreshing = true;
                    this.isTokenRequesting = true;
                    return this.loginWithApiKeyAndRetry(apiKey.api_key, currentUser, request, next).pipe(
                        finalize(() => {
                            this.isTokenRequesting = false;
                        })
                    );
                }
            }
            // If neither the refresh token nor the API key are valid, log out
            this.doLogout();
            return throwError(() => new HttpErrorResponse({ status: 401 }));
        }
    }

    private refreshTokenAndRetry(refreshToken: string, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authenticationService.loginWithRefreshToken(refreshToken).pipe(
            switchMap((newAuthModel: authModel) => {
                const newToken = newAuthModel.access_token;
                if (newToken) {
                    this.tokenSubject.next(newAuthModel);
                    const tokenReq = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${newToken}`,
                        },
                    });
                    return next.handle(tokenReq);
                } else {
                    this.doLogout();
                    return throwError(() => new HttpErrorResponse({ status: 401 }));
                }
            }),
            catchError((error) => {
                this.doLogout();
                return throwError(() => new Error(error));
            }),
            finalize(() => {
                this.isRefreshing = false;
            }),
            shareReplay(1)
        );
    }

    private loginWithApiKeyAndRetry(apiKey: string, currentUser: authModel, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authenticationService.loginWithApiKey(apiKey, currentUser.username, String(currentUser.device_token)).pipe(
            switchMap((newAuthModel: authModel) => {
                const newToken = newAuthModel.access_token;
                if (newToken) {
                    this.tokenSubject.next(newAuthModel);
                    const tokenReq = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${newToken}`,
                        },
                    });
                    return this.authenticationService.requestApiKey(String(currentUser.device_token)).pipe(
                        switchMap(() => next.handle(tokenReq))
                    );
                } else {
                    this.doLogout();
                    return throwError(() => new HttpErrorResponse({ status: 401 }));
                }
            }),
            catchError((error) => {
                this.doLogout();
                return throwError(() => new Error(error));
            }),
            finalize(() => {
                this.isRefreshing = false;
            }),
            shareReplay(1)
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private doLogout(): void {
        localStorage.clear();
        this.authenticationService.logout();
    }
}
