import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from '../../Modules/authentication.service';

@Component({
    templateUrl: 'select_company.component.html',
    styleUrls: ['select_company.component.scss']
})
export class SelectCompanyComponent {
    loading = false;
    userInformation: any;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private ngxService: NgxUiLoaderService
    ) { }

    ngOnInit() {
        this.userInformation = JSON.parse(localStorage.getItem('authUserInformation'));
    }

    onSubmit() {
        this.loading = true;
    }

    selectCompany(company: any) {
        this.ngxService.start();
        this.authenticationService.updateUserInformation(company.id, this.userInformation.id).subscribe(data => {
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.ngxService.stop();
            this.router.navigateByUrl('/home');
        }, error => {
            this.ngxService.stop();
            this.authenticationService.logout();
        })
    }

    logout() {
        this.authenticationService.logout();
    }
}
