import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../Modules/authentication.service';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({ 
    templateUrl: 'forgot_password.html',
    styleUrls: ['forgot_password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    public loading = false;
    public resetForm: FormGroup;
    public submitted = false;
    public loginTypes: any;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.loginTypes = environment.level;
        this.resetForm = this.formBuilder.group({
            username: ['', Validators.required],
            login_type: [environment.level[0]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetForm.controls; }

    onSubmit() {
        this.submitted = true;
        
        if (this.resetForm.invalid) {
            this.toastr.error('Formulier is niet geldig. Vul alle verplichte velden in.', 'Ongeldig formulier');
            return;
        }

        this.loading = true;

        this.authenticationService.forgotPassword(this.f['username'].value, this.f['login_type'].value).subscribe(
            data => {
                this.loading = false;

                if (data.error) {
                    this.toastr.error(JSON.stringify(data.message), 'Fout bij wachtwoord reset');
                } else {
                    this.toastr.success('Er is een mail verstuurd met een tijdelijk wachtwoord!', 'Succes');
                }
            },
            error => {
                this.loading = false;
                this.toastr.error('Er is een fout opgetreden bij het resetten van uw wachtwoord.', 'Serverfout');
            }
        );
    }
}
