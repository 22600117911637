<app-headermenu></app-headermenu>
<div class="container container-voorwaarden">
  <div class="container">
    <div style="padding: 10px; overflow: auto;">
      <h1 style="font-size:30px;">Voorwaarden Expertteam<br><br></h1>
      <h3>Artikel 1 | Gebruik van toegangscode</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                1.1
              </p>
            </td>
            <td>
              <p>
                ATP verleent aan een Expertlid het recht om middels een zogenaamde toegangscode in te loggen en gebruik
                te maken van het Platform van ATP voor het uitbrengen van een bod op een voertuig en voor het bekijken
                van de biedinggegevens van het Expertlid. Bij het gebruik van het Platform zal het Expertlid te allen
                tijde de instructies opvolgen van ATP. ATP is te allen tijde bevoegd om de aan een Expertlid verleende
                toegangscode in te trekken of de werking daarvan tijdelijk op te schorten. De toegangscode die ATP aan
                een Expertlid ter beschikking stelt is niet overdraagbaar en mag uitsluitend worden gebruikt door een
                handelsbevoegde functionaris bij het Expertlid. Tenzij anders overeengekomen, zal dit de contactpersoon
                van ATP zijn. Bij overtreding van het bepaalde in dit artikel is Expertlid een direct opeisbare en niet
                voor verrekening vatbare boete verschuldigd van EUR 1.000,00 per overtreding. Deze boete laat het recht
                van ATP op schadevergoeding en/of nakoming onverlet. Een Expertlid kan tijdens de kantooruren van ATP
                een gewijzigde toegangscode aanvragen bij ATP.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                1.2
              </p>
            </td>
            <td>
              <p>
                Indien door ATP aan het Expertlid een (tijdelijke) toegangscode voor het Platform ter beschikking wordt
                gesteld waarmee toegang kan worden verkregen tot (een gedeelte van) het Platform zal ATP (in voorkomend
                geval) gerechtigd zijn om (persoons)gegevens van het Expertlid (en/of diens vertegenwoordiger) op te
                slaan in een daarvoor aangehouden administratie.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                1.3
              </p>
            </td>
            <td>
              <p>
                Het Expertlid is jegens ATP gehouden om de computer en/of ieder ander apparaat waarmee het Expertlid
                zich toegang verschaft tot het Platform afdoende en zoals gebruikelijk te beveiligen tegen virussen of
                andere onrechtmatige programma's of bestanden die zich kunnen verspreiden via het Internet. Voorts dient
                het Expertlid voldoende (beveiligings)maatregelen te nemen om een ongeautoriseerd gebruik van de
                toegangscode door derden tegen te gaan. Het Expertlid is jegens ATP aansprakelijk voor alle handelingen
                die op een Platform plaatsvinden en die voortvloeien uit het gebruik van de toegangscode die aan het
                Expertlid is verstrekt, ook in geval van misbruik.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 2 | Beschikbaarheid Platform</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                2.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid aanvaardt de bijzondere omstandigheden die bij een online handelssysteem kunnen spelen en
                de (technische) onvolkomenheden die zich daarbij kunnen voordoen. Daarbij dient onder meer te worden
                gedacht aan: de onmogelijkheid voor een Expertlid om (volledige) toegang te verkrijgen tot het Platform,
                het niet of niet tijdig kunnen uitbrengen van een bod, storingen van en/of gebreken in het Platform en/of
                in de onderliggende hardware en/of netwerkverbindingen en/of de software waar het Platform op draait.
                ATP streeft ernaar om incidenten die ertoe leiden dat het Platform niet naar behoren functioneert binnen
                een redelijke termijn op te lossen, maar kan niet garanderen dat het Platform onder alle omstandigheden
                naar behoren functioneert en bereikbaar is voor het Expertlid. Voorts kunnen onderhoudswerkzaamheden aan
                het Platform en/of aan het onderliggende systeem aan een onbeperkte toegang tot het Platform in de weg
                staan. ATP is gerechtigd om zonder voorafgaande bekendmaking het Platform (tijdelijk) buiten gebruik te
                stellen of het gebruik ervan te beperken voor zover dit, naar het oordeel van ATP, noodzakelijk is voor
                het redelijkerwijs benodigde onderhoud of voor de noodzakelijk door ATP te verrichten aanpassingen of
                verbeteringen aan het Platform zonder dat hierdoor een recht op schadevergoeding of enig ander recht
                van het Expertlid jegens ATP ontstaat. ATP aanvaardt geen enkele aansprakelijkheid voor eventuele gevolgen
                van (tijdelijke) niet-beschikbaarheid van het Platform. Het staat ATP te allen tijde vrij de toegang tot
                (een gedeelte van) het Platform, om haar moverende redenen, voor het Expertlid te blokkeren, bijvoorbeeld
                als het Expertlid, naar het oordeel van ATP, in strijd handelt met deze voorwaarden.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                2.2
              </p>
            </td>
            <td>
              <p>
                ATP streeft ernaar om middels het Platform zoveel mogelijk voertuigen aan te bieden waarop door het
                Expertlid een bod kan worden uitgebracht. ATP zal op het Platform een omschrijving van de voertuigen ter
                inzage beschikbaar stellen, met inachtneming van de selectiecriteria die ATP met het Expertlid overeenkomt,
                en welke selectiecriteria door Partijen ook wel worden aangeduid als: "het biedfilter".
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 3 | Omschrijving Voertuig</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                3.1
              </p>
            </td>
            <td>
              <p>
                Indien beschikbaar, wordt de omschrijving van de voertuigen op de website van ATP voorzien van foto's.
                ATP streeft ernaar de omschrijvingen van de voertuigen zo getrouw mogelijk te geven, maar kan niet
                instaan voor de juistheid en volledigheid van de omschrijvingen van de voertuigen. Het Expertlid is
                ermee bekend dat voor de omschrijvingen van de voertuigen door ATP gebruik wordt gemaakt van (informatie
                van) derden. Omschrijvingen van een voertuig en alle overige informatie die al dan niet via het Platform
                ter beschikking wordt gesteld, worden door ATP naar "beste weten" beschikbaar gesteld. ATP maakt bij de
                omschrijving van een voertuig op haar Platform gebruik van de door een (zakelijke) aanbieder en/of derden
                verstrekte informatie over het voertuig. ATP heeft geen kennis van het voertuig noch van eventuele (beperkte)
                rechten (van derden), lasten of beslagen verbonden aan het voertuig. Het is voor ATP niet mogelijk om na
                te gaan of de aan ATP ten behoeve van het Platform verstrekte door de (zakelijke) aanbieder en/of door
                derden verstrekte informatie over het voertuig juist en volledig is. ATP behoudt zich voorts het recht
                voor om eventuele verschrijvingen te kunnen wijzigen. Partijen komen overeen dat een onjuiste en/of
                onvolledige omschrijving van een voertuig geen tekortkoming oplevert aan de zijde van ATP en dat het
                Expertlid ATP niet aansprakelijk zal stellen voor eventuele schade of anderszins vorderingen zal instellen
                jegens ATP als de omschrijving van het voertuig niet juist of onvolledig blijkt te zijn.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 4 | Bieden</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                4.1
              </p>
            </td>
            <td>
              <p>
                ATP streeft ernaar om, zo spoedig mogelijk na het sluiten van de dagelijkse biedtermijn, aan het Expertlid
                kenbaar te maken op welke voertuigen het Expertlid het hoogste bod heeft uitgebracht. Het Expertlid is jegens
                ATP gehouden om een uitgebracht bod gedurende 30 dagen na het uitbrengen ervan gestand te doen. De biedtermijn
                sluit dagelijks om 11.00 uur met uitzondering van de zondag en de feestdagen. ATP is te allen tijde gerechtigd
                om de biedtermijn op te schorten, in te korten of te verlengen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                4.2
              </p>
            </td>
            <td>
              <p>
                Aan de zijde van ATP is steeds slechts sprake van een (openbare) uitnodiging aan het Expertlid tot het doen
                van een aanbod middels het uitbrengen van een bod binnen de daarvoor door ATP aangegeven biedtermijn.
                Een koopovereenkomst komt pas tot stand als ATP een aanbod van een Expertlid heeft aanvaard en ATP deze
                aanvaarding aan het Expertlid heeft bevestigd met een verkoopbevestiging of middels berichtgeving op het
                Platform. ATP behoudt zich uitdrukkelijk het recht voor om een aanbod van een Expertlid niet te aanvaarden,
                ook al betreft het een hoogste bieding. Indien een aanbod door ATP niet wordt aanvaard, komt er tussen
                Partijen geen koopovereenkomst tot stand. ATP streeft er evenwel naar om uit zoveel mogelijk biedingen
                daadwerkelijk koopovereenkomsten tot stand te laten komen, waarbij voor ATP van belang is dat zij daarmee
                in ieder geval haar kosten (voor onder meer het gebruikelijke opslag en transport) vergoed krijgt en een
                van te voren vastgestelde winstmarge realiseert. Er zal in beginsel door ATP niet worden onderhandeld over
                een uitgebracht bod, tenzij er sprake is van een te lage winstmarge voor ATP. In dat geval behoudt ATP
                zich het recht voor om met een Expertlid in overleg te treden.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                4.3
              </p>
            </td>
            <td>
              <p>
                Bij personenauto's vinden biedingen altijd plaats inclusief BTW en BPM tenzij anders aangegeven, ongeacht
                of het een marge-auto of een btw-auto is. Voor bedrijfswagens dient de bieding altijd exclusief BPM en
                inclusief BTW plaats te vinden.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                4.4
              </p>
            </td>
            <td>
              <p>
                Het Expertlid is zelf verantwoordelijk voor controle van de teruggave mogelijkheden van de rest-bpm.
                Het Expertlid kan op geen enkele wijze ATP aansprakelijk stellen in geval van niet terug te ontvangen
                rest-bpm bij export.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 5 | Performance</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                5.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid verplicht zich jegens ATP tot het regelmatig uitbrengen van reële en marktconforme biedingen
                op de voertuigen waarop via het Platform een bod kan worden uitgebracht.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 6 | Koop en verkoop</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                6.1
              </p>
            </td>
            <td>
              <p>
                Een koopovereenkomst komt pas tot stand als ATP een aanbod van een Expertlid heeft aanvaard en ATP deze
                aanvaarding aan het Expertlid heeft bevestigd met een verkoopbevestiging of middels berichtgeving op het
                Platform. ATP zal een Expertlid pas in kennis stellen van een aanvaarding van het aanbod indien de (zakelijke)
                aanbieder van wie ATP op haar beurt het voertuig koopt, de verkoop van het voertuig aan ATP heeft bevestigd.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                6.2
              </p>
            </td>
            <td>
              <p>
                Onverminderd het overige bepaalde in deze voorwaarden is ATP gerechtigd om een koopovereenkomst met een
                Expertlid te beëindigen, zonder dat daardoor voor het Expertlid jegens ATP vorderingen (tot vergoeding van
                schade of anderszins) ontstaan, indien de (zakelijke) aanbieder haar verplichtingen jegens ATP (zoals ten
                aanzien van de levering van het voertuig) niet nakomt of indien ATP besluit om, om haar moverende redenen,
                de koopovereenkomst met deze (zakelijke) aanbieder te beëindigen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                6.3
              </p>
            </td>
            <td>
              <p>
                Het Expertlid is zich bewust van het feit dat het door het Expertlid gekochte voertuig pas door het
                Expertlid mag worden geadverteerd na de levering van het voertuig aan het Expertlid. Het is het Expertlid
                daarbij niet toegestaan om eerder verkregen beeldmateriaal met betrekking tot het voertuig te gebruiken.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 7 | Levering</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                7.1
              </p>
            </td>
            <td>
              <p>
                De termijn die geldt voor de levering van een voertuig door ATP aan een Expertlid begint pas te lopen
                nadat de (zakelijke) aanbieder van het voertuig aan ATP kenbaar heeft gemaakt dat het voertuig voor afhalen
                beschikbaar is of nadat het bezit van het voertuig aan ATP is verschaft. De door ATP bij de bevestiging aan
                het Expertlid aangegeven levertermijn is, tenzij uitdrukkelijk anders overeengekomen, geen fatale termijn,
                maar is slechts een indicatieve termijn. Zolang de door ATP aangegeven levertermijn met niet meer dan 30
                dagen wordt verlengd, is het Expertlid gehouden tot afname van het voertuig. Daarna heeft het Expertlid
                slechts het recht de koopovereenkomst te beëindigen wegens niet tijdige levering indien het Expertlid ATP
                schriftelijk per aangetekende post in gebreke stelt en een termijn van een week is verstreken waarin het
                ATP vrijstaat om in deze periode alsnog de levering van het voertuig na te komen. In dat geval is ATP jegens
                het Expertlid niet gehouden tot vergoeding van eventuele schade, noch ontstaan er in dat geval andere
                rechten voor het Expert lid jegens ATP.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                7.2
              </p>
            </td>
            <td>
              <p>
                Levering kan plaatsvinden door het afleveren van het voertuig door of namens ATP op het door het Expertlid
                aangeven adres, waarbij, naast het aankoopbedrag, een bedrag van EUR 91,- excl. btw aan transportkosten aan
                het Expertlid in rekening zal worden gebracht. Indien het voertuig door een derde partij gehouden wordt voor
                ATP, kan levering van het voertuig, naar keuze van ATP, ook plaatsvinden door middel van een mededeling van
                ATP aan het Expertlid en aan die derde inhoudende dat het bezit van het voertuig aan het Expertlid is overgedragen
                en die derde het voertuig vanaf dat moment voor het Expertlid zal houden.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                7.3
              </p>
            </td>
            <td>
              <p>
                Het Expertlid is verplicht een gekocht voertuig af te nemen op het moment waarop deze aan het Expertlid
                wordt geleverd. Indien het Expertlid de afname weigert of nalatig is met het verstrekken van informatie
                of instructies, noodzakelijk voor de levering, dan zal het voertuig worden opgeslagen voor risico van het
                Expertlid. Het Expertlid zal in dat geval alle aanvullende kosten, waaronder in ieder geval opslagkosten,
                verschuldigd zijn. Deze bepaling laat onverlet het recht van ATP om de koopovereenkomst te ontbinden en
                van het Expertlid een schadevergoeding te verlangen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 8 | Klachtplicht/Reclameren</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                8.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid dient een gekocht voertuig bij levering te (laten) onderzoeken. Ongeacht het bepaalde
                in artikel 6:89 Burgerlijk Wetboek en/of artikel 7:23 Burgerlijk Wetboek dient een Expertlid uiterlijk
                binnen vijf werkdagen na de levering van het voertuig aan het Expertlid, ATP gemotiveerd en voorzien van
                foto's te informeren over eventuele gebreken aan het voertuig, op straffe van verval van eventuele rechten
                jegens ATP. Indien binnen deze termijn het Expertlid niet op de hiervoor voorgeschreven wijze heeft geklaagd
                over gebreken aan het voertuig, dan geldt het voertuig als door het Expertlid voetstoots aanvaardt met alle
                eventuele (technische of rechts) gebreken. Indien er sprake is van gebreken aan het voertuig, waarover door
                het Expertlid tijdig is geklaagd, dan geldt er ten aanzien van deze gebreken een eigen risico voor het
                Expertlid van 350,- euro exclusief btw en een maximaal door het Expertlid van ATP te claimen/vorderen bedrag
                van 20% van de door het Expertlid voor het voertuig betaalde koopprijs. Onverminderd het hiervoor in dit
                artikel bepaalde, dient een eventueel herstel van gebreken plaats te vinden op aanwijzing van ATP en zal
                er ter bepaling van de kosten/schade ten aanzien van het herstel van eventuele gebreken worden uitgegaan
                van een uurtarief van maximaal EUR 55, - exclusief btw met dien verstande dat deze uren moeten zien op
                werkelijk verrichtte werkzaamheden en ATP nimmer voor een hoger bedrag aansprakelijk kan worden gehouden
                dan 20% van de koopprijs voor het voertuig en met inachtneming van een eigen risico voor het Expertlid
                van EUR 350, -. Herstel van gebreken zonder overleg met ATP is zonder meer voor rekening en risico
                van het Expertlid.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                8.2
              </p>
            </td>
            <td>
              <p>
                Indien de te leveren zaken buiten Nederland zullen worden gebruikt is verkoper er niet verantwoordelijk
                voor dat de te leveren zaken voldoen aan de technische eisen of normen die worden gesteld door wetten of
                bepalingen van het land waar de zaken zullen worden gebruikt, tenzij bij het sluiten van de koop van het
                gebruik in het buitenland melding is gemaakt, onder opgave aan welke specifieke technische eisen of normen
                dient te worden voldaan in het land van gebruik. Ook alle andere technische eisen die door de koper aan de
                te leveren zaken worden gesteld en welke afwijken van de normale eisen, dienen bij het sluiten van de
                koopovereenkomst door de koper nadrukkelijk te worden gemeld.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                8.3
              </p>
            </td>
            <td>
              <p>
                Onverminderd het overige bepaalde in deze voorwaarden geldt dat de volgende technische zaken in ieder geval
                niet als een gebrek worden aangemerkt:
              </p>
              <ul>
                <li>EGR klep</li>
                <li>Airco afvullen</li>
                <li>Luchtmassameter</li>
                <li>Kapotte Accu</li>
                <li>(Twee) Massa Vliegwiel</li>
                <li>Alle slijtagedelen</li>
                <li>Preventieve reparatie en onderhoud</li>
              </ul>
              <p></p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                8.4
              </p>
            </td>
            <td>
              <p>
                Wanneer tussen Partijen geen overeenstemming kan worden bereikt over het oplossen van een geschil, heeft
                ATP het recht de koopovereenkomst te beëindigen, het voertuig op te halen en de betaalde koopprijs aan
                het Expertlid terug te betalen, zonder dat er in dat geval voor het Expertlid aanspraken jegens ATP ontstaan
                tot vergoeding van schade of anderszins. Het Expertlid is in dat geval verplicht om het voertuig in de staat
                waarin deze aan het Expert lid is geleverd aan ATP ter beschikking te stellen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 9 | Eigendomsvoorbehoud</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                9.1
              </p>
            </td>
            <td>
              <p>
                Alle door ATP aan het Expertlid geleverde voertuigen blijven eigendom van ATP, totdat alle door Expertlid
                krachtens de koopovereenkomst verschuldigde vergoedingen aan ATP zijn betaald. Het Expertlid is niet
                beschikkingsbevoegd ten aanzien van voertuigen, waarop het hiervoor in dit artikel bedoelde eigendomsvoorbehoud
                rust en is verplicht belanghebbenden - waaronder beoogde rechtverkrijgenden - van die beschikkingsonbevoegdheid
                in kennis te stellen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                9.2
              </p>
            </td>
            <td>
              <p>
                De koper verplicht zich jegens verkoper om de onder eigendomsvoorbehoud geleverde zaken te verzekeren en
                verzekerd te houden tegen brand-, en ontploffings- en waterschade en tegen diefstal en de polis van deze
                verzekering op eerste verzoek van verkoper ter inzage te geven; alle aanspraken van de koper op verzekeraars
                met betrekking tot de onder eigendomsvoorbehoud geleverde zaken te verpanden aan verkoper op de manier
                die wordt voorgeschreven in art. 3:239 B.W.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 10 | Betaling</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                10.1
              </p>
            </td>
            <td>
              <p>
                Betaling van de bedragen die het Expertlid aan ATP verschuldigd is (waaronder de koopprijs, maar ook kosten
                van transport) geschiedt door middel van een automatische incasso, tenzij ATP aangeeft dat er op andere
                wijze dient te worden betaald. Het Expertlid verplicht zich jegens ATP om alle medewerking te verlenen
                aan deze automatische incasso. Onder meer dient het Expertlid ervoor te zorgen dat de daartoe bevoegde
                persoon bij het Expertlid de bank persoonlijk toestemming geeft voor deze doorlopende automatische incasso.
                De opdracht tot automatische incasso wordt gegeven nadat ATP de auto heeft betaald aan de (zakelijke)
                aanbieder. ATP streeft ernaar om het voertuig binnen 5 werkdagen nadien te leveren aan het Expertlid.
                Incasso zal geschieden zonder inhouding, korting en/of verrekening.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 11 | Tussentijdse beëindiging</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
        <tr>
          <td valign="top">
            <p>
              11.1
            </p>
          </td>
          <td>
            <p>
              Onverminderd enig ander recht heeft ATP het recht om haar relatie met het Expertlid met onmiddellijke
              ingang te beëindigen, indien:
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.1
            </p>
          </td>
          <td>
            <p>
              het Expertlid onvoldoende aanbiedt c.q. afneemt via het platform;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.2
            </p>
          </td>
          <td>
            <p>
              het Expertlid niet (langer) beschikt over een RDW-erkenning "bedrijfsvoorraad";
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.3
            </p>
          </td>
          <td>
            <p>
              het Expertlid niet tijdig aan zijn betalingsverplichting voldoet;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.4
            </p>
          </td>
          <td>
            <p>
              het faillissement van Expertlid is aangevraagd of Expertlid in staat van faillissement is verklaard;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.5
            </p>
          </td>
          <td>
            <p>
              het Expertlid surseance van betaling aanvraagt of aan het Expertlid surseance van betaling is verleend;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.6
            </p>
          </td>
          <td>
            <p>
              het Expertlid aan zijn crediteuren een onderhands akkoord aanbiedt;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.7
            </p>
          </td>
          <td>
            <p>
              het Expertlid op enige wijze in zijn bevoegdheid rechtshandelingen te verrichten, wordt beperkt;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.8
            </p>
          </td>
          <td>
            <p>
              het Expertlid besluit tot ontbinding en overgaat tot liquidatie;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.9
            </p>
          </td>
          <td>
            <p>
              het Expertlid getroffen wordt door (conservatoir of executoriaal) beslag op zijn vermogen,
              waaronder ook zijn (handels)voorraad, voertuigen of andere eigendommen, en dit beslag na 2 weken niet is opgeheven;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.10
            </p>
          </td>
          <td>
            <p>
              het Expertlid tekortschiet in de nakoming van zijn verplichtingen onder deze voorwaarden en te dien aanzien
              in verzuim is;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.11
            </p>
          </td>
          <td>
            <p>
              de door Expertlid geëxploiteerde onderneming geheel of ten dele aan een of meer anderen wordt of is overgedragen;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.12
            </p>
          </td>
          <td>
            <p>
              ATP om welke reden dan ook het recht verliest om gegevens te leveren die noodzakelijk zijn voor de uitvoering
              van het Platform;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.13
            </p>
          </td>
          <td>
            <p>
              een toeleverancier van gegevens toestemming onthoudt tot gebruik en/of doorlevering van de gegevens al dan
              niet ten behoeve van Expertlid;
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.1.14
            </p>
          </td>
          <td>
            <p>
              Een Expertlid is gehouden om aan ATP terstond melding te maken indien een van de hierboven onder
              11.1.1 t/m 11.1.11 genoemde situaties zich voordoet. ATP is wegens het beëindigen van toegang tot het
              Platform en het opschorten van daaruit voortvloeiende verbintenissen op grond van de hiervoor bedoelde
              gebeurtenissen nimmer enige schadevergoeding aan Expertlid verschuldigd. Eventuele rechten die het
              Expertlid heeft verkregen met betrekking tot het gebruik van het Platform (zoals ten aanzien van een
              eventueel inlog account) vervallen terstond bij beëindiging/einde van de toegang.
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.2
            </p>
          </td>
          <td>
            <p>
              Indien de toegang tot het Platform wordt beëindigd, vallen de door Expertlid reeds ontvangen prestaties
              en de daarmee verband houdende betalingsverplichtingen van Expertlid niet onder de ongedaanmakingsverplichting,
              tenzij ATP ten aanzien van die prestaties in verzuim is. Door ATP gefactureerde bedragen in verband met voor
              of bij het beëindigen van de toegang tot het Platform verrichte prestaties zijn na de ontbinding onmiddellijk
              opeisbaar.
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.3
            </p>
          </td>
          <td>
            <p>
              Onverminderd het overig bepaalde in deze voorwaarden is ATP altijd gerechtigd om, ongeacht de reden de toegang
              tot het Platform te beëindigen met inachtneming van een opzegtermijn van één (1) maand. De Opzegging laat eventueel
              nog lopende verplichtingen voor een Expertlid jegens ATP, zoals deze bijvoorbeeld kunnen voortvloeien uit
              een uitgebracht bod van het Expertlid onverlet.
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <p>
              11.3.1
            </p>
          </td>
          <td>
            <p>
              Bepalingen van de voorwaarden die naar hun aard bestemd zijn om ook na einde/beëindiging van de
              toegang tot het Platform voort te duren, blijven na beëindiging / bestaan. De beëindiging/ van de toegang
              tot het Platform ontslaat Partijen uitdrukkelijk niet van het bepaalde met betrekking tot geheimhouding,
              aansprakelijkheid, intellectuele eigendomsrechten, toepasselijk recht en forumkeuze.
            </p>
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 12 | Overmacht</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                12.1
              </p>
            </td>
            <td>
              <p>
                Indien ATP door overmacht tijdelijk niet in staat is om haar verplichtingen uit te voeren, is zij bevoegd
                het uitvoeren van geheel of ten dele op te schorten zolang de overmacht voortduurt. Indien ATP door
                overmacht blijvend niet in staat is  uit te voeren, heeft zij het recht haar relatie met het Expertlid
                met onmiddellijke ingang geheel of ten dele te beëindigen. Onder overmacht aan de zijde van ATP wordt
                in ieder geval verstaan: werkstaking, bovenmatig ziekteverzuim van het personeel van ATP, transportmoeilijkheden,
                brand, waterschade, overheidsmaatregelen, het niet tijdig beschikbaar zijn van noodzakelijke gegevens,
                gegevensbestanden en/of andere informatie, het uitvallen van elektriciteit of storingen in netwerken,
                systeem-/netwerkbelasting, virussen dan wel andere verstoringen van het Platform, wanprestatie door
                toeleveranciers (zoals een particuliere) aanbieder van een voertuig en/of derden die door ATP worden
                ingeschakeld waardoor ATP haar verplichtingen jegens het Expertlid niet kan nakomen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 13 | Aansprakelijkheid, schadevergoeding en vrijwaring</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                13.1
              </p>
            </td>
            <td>
              <p>
                Behoudens voor zover zulks naar Nederlands recht onmogelijk is, is iedere aansprakelijkheid van ATP, voor
                zover daarvan gelet op het overige bepaalde in deze voorwaarden (nog) sprake zou zijn, beperkt tot het
                bedrag dat in het desbetreffende geval uit hoofde van een door ATP gesloten aansprakelijkheidsverzekering
                wordt gedekt. Indien en voor zover om welke reden dan ook er geen uitkering door de verzekeraar mocht
                plaatsvinden, is iedere aansprakelijkheid van ATP jegens het Expertlid beperkt tot een maximumbedrag van
                EUR 500, -.
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                13.2
              </p>
            </td>
            <td>
              <p>
                Aansprakelijkheid van ATP voor indirecte schade, waaronder begrepen gevolgschade, gederfde winst,
                gemiste besparingen, vernietiging of zoekraken van bestanden en/of gegevens, vertragingsschade, geleden
                verlies, schade veroorzaakt door het gebrekkig verschaffen van informatie, schade door bedrijfstagnatie
                of door vorderingen van derden op Expertlid uitdrukkelijk uitgesloten.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                13.3
              </p>
            </td>
            <td>
              <p>
                Onverminderd het bepaalde in artikel 6:89 Burgerlijk Wetboek komt iedere aanspraak van een Expert lid
                jegens ATP in ieder geval te vervallen indien het Expertlid niet binnen 24 uur nadat een prestatie door
                ATP is geleverd ATP van een gebeurtenis of omstandigheid die aanleiding geeft of kan geven tot aansprakelijkheid,
                van de aanspraak schriftelijk in kennis heeft gesteld.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 14 | Vrijwaring en kettingbeding</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                14.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid zal ATP en de eventueel door ATP betrokken derden vrijwaren en op eerste verzoek schadeloos
                stellen voor alle schade en kosten in verband met vorderingen van derden  door elk van de Partijen en/of
                voor vorderingen van derden naar aanleiding van deelname van het Expertluid aan het Platform.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                14.2
              </p>
            </td>
            <td>
              <p>
                Derden die door ATP bij de uitvoering van haar verplichtingen worden betrokken, kunnen jegens het Expertlid
                een beroep doen op de aansprakelijkheidsbeperkende bepalingen uit deze voorwaarden, welke bepaling bij
                wijze van derden beding door deze derden wordt aanvaard.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                14.3
              </p>
            </td>
            <td>
              <p>
                Het Expertlid zal ATP in voorkomend geval vrijwaren en op eerste verzoek schadeloosstellen in het geval
                van vorderingen van derden in verband met het gebruik van eventuele persoonsgegevens (al dan niet op grond
                van de Wet bescherming Persoonsgegevens). Het bepaalde in dit artikel geldt onder meer, maar niet uitsluitend,
                in de situatie dat er zich in het voertuig nog (persoons)gegevens van derden, zoals van een (particuliere)
                aanbieder zouden bevinden.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 15 | Onderaanneming</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                15.1
              </p>
            </td>
            <td>
              <p>
                ATP is steeds gerechtigd om bij de uitvoering van haar verplichtingen (al dan niet tijdelijk) gebruik te
                maken van de diensten van derden die daarbij onverminderd rechten kunnen ontlenen aan de bepalingen die
                gelden voor ATP in de voorwaarden. ATP is niet aansprakelijk voor enig handelen of nalaten van door haar
                ingeschakelde derden.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 16 | Bedrijfsuitoefening</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                16.1
              </p>
            </td>
            <td>
              <p>
                ATP oefent geheel voor eigen rekening en risico een bedrijf uit. Hetzelfde geldt voor het Expertlid.
                ATP noch het Expertlid zijn, tenzij uitdrukkelijk anders schriftelijk overeengekomen, in geen enkel opzicht
                vertegenwoordiger of agent van elkaar en hebben mitsdien geen enkele bevoegdheid om namens elkander enige
                verbintenis aan te gaan.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 17 | Contactpersoon</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                17.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid wijst voor de duur van de toegang tot het Platform een contactpersoon aan en verleent deze
                contactpersoon volmacht om met ATP alle uit de voorwaarden voortvloeiende afspraken te maken. Deze contactpersoon
                is degene die de toegangscodes verkrijgt en die geacht wordt namens het Expertlid de biedingen uit te brengen.
                Als de contactpersoon bij het Expertlid deze functie verliest, is het Expertlid gehouden ATP daarvan terstond
                in kennis te stellen en dient er in overleg met ATP een nieuwe contactpersoon te worden aangewezen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 18 | Geheimhouding</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                18.1
              </p>
            </td>
            <td>
              <p>
                Het Expertlid zal vertrouwelijke informatie afkomstig van (het Platform van) ATP vertrouwelijk behandelen
                en slechts gebruiken voor de doeleinden waarvoor deze informatie aan het Expertlid is verstrekt/ter
                beschikking is gesteld en niet ter beschikking stellen aan derden zonder voorafgaande toestemming van ATP.
                Het Expertlid zal adequate maatregelen nemen om vertrouwelijke informatie vertrouwelijk te houden.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 19 | (Persoons)gegevens en beveiliging</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                19.1
              </p>
            </td>
            <td>
              <p>
                Indien en voor zover sprake is van de verwerking van persoonsgegevens, verplichten Partijen zich, reeds
                nu voor alsdan, jegens elkaar om te zorgen voor passende technische en organisatorische maatregelen om
                de persoonsgegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking, een en
                ander in overeenstemming met de in dat kader toepasselijke wetgeving.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                19.2
              </p>
            </td>
            <td>
              <p>
                Ten aanzien van het privacybeleid en cookybeleid van ATP bij ieder bezoek aan het Platform geldt (voorts)
                het bepaalde in de privacy- en cookyverklaring die op www.autotaxatiepartners.nl/voorwaarden te raadplegen zijn.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 20 | Toepasselijk recht en bevoegde rechter</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                20.1
              </p>
            </td>
            <td>
              <p>
                Op eventuele geschillen die ontstaan tussen Expertlid en ATP is Nederlands recht van toepassing.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                20.2
              </p>
            </td>
            <td>
              <p>
                De Rechtbank Gelderland, locatie Arnhem is in eerste aanleg exclusief bevoegd om kennis te nemen van
                eventuele geschillen die voortvloeien.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 21 | Rechten van intellectuele eigendom</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                21.1
              </p>
            </td>
            <td>
              <p>
                Alle rechten van intellectuele eigendom ten aanzien van het Platform en alle krachtens en/of in het kader
                van toegang tot het Platform tot stand gebrachte, verstrekte en/of toegankelijke voortbrengselen - zoals
                onder meer teksten, gegevens, tekeningen, analyses, rapporten, methoden, technologieën, computerprogrammatuur,
                databanken en documentatie - berusten uitsluitend bij ATP en/of haar licentiegever(s). Van een overdracht
                van intellectuele eigendomsrechten is geen sprake.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                21.2
              </p>
            </td>
            <td>
              <p>
                Het is het Expertlid niet toegestaan om zonder voorafgaande toestemming van ATP, het Platform of
                een gedeelte daarvan te verveelvoudigen dan wel ter beschikking te stellen (middels deep linken, framing
                of anderszins). Het bepaalde in de Copyright Verklaring op het Platform van ATP (zoals deze van tijd tot
                tijd door ATP kan worden gewijzigd) is onverkort van toepassing.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                21.3
              </p>
            </td>
            <td>
              <p>
                ATP is gerechtigd door Expertlid verstrekte gegevens op te nemen in een eigen, door ATP te exploiteren databank.
                ATP is onder andere gerechtigd deze gegevens te bewaren, commercieel te gebruiken en, binnen de grenzen als
                wettelijk is toegestaan, deze gegevens aan derden door te leveren dan wel beschikbaar te stellen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                21.4
              </p>
            </td>
            <td>
              <p>
                Het Expertlid is verplicht om eventuele gegevens(bestanden) en/of andere door ATP verstrekte informatie en/of
                inlogcodes alleen te gebruiken voor het doel waarvoor deze door ATP zijn verstrekt en/of ter beschikking zijn
                gesteld en het Expertlid is verplicht om na gebruik voor het doel waarvoor deze gegevens zijn verstrekt en/of
                op eerste verzoek van ATP deze gegevens (waaronder ook het inlog account) te vernietigen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <h3>Artikel 22 | Overige</h3><br>
      <table width="100%" border="0" cellpadding="0">
        <tbody>
          <tr>
            <td valign="top">
              <p>
                22.1
              </p>
            </td>
            <td>
              <p>
                Indien één of meerdere bepalingen in deze voorwaarden nietig zijn of vernietigd mochten worden blijven de
                overige bepalingen van deze voorwaarden volledig van toepassing. Partijen zullen alsdan in overleg treden
                teneinde nieuwe bepalingen ter vervanging van de nietige c.q. vernietigde bepalingen overeen te komen,
                waarbij indien en voor zoveel mogelijk het doel en de strekking van de oorspronkelijke bepaling in acht
                worden genomen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                22.2
              </p>
            </td>
            <td>
              <p>
                Indien en voor zover er uit deze voorwaarden termijnen voortvloeien waaraan ATP zich dient te houden,
                dan gelden deze termijnen niet als fatale termijnen.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                22.3
              </p>
            </td>
            <td>
              <p>
                Alle werkzaamheden zullen door ATP op werkdagen en onder normale arbeidsomstandigheden worden uitgevoerd.
              </p>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                22.4
              </p>
            </td>
            <td>
              <p>
                Door het Expertlid of door een derde gehanteerde algemene voorwaarden worden uitdrukkelijk van de hand gewezen.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <br>
      <br>
      <h1 style="font-size:20px;">Cookie statement</h1>
      <p>
        Autotaxatie Partners maakt bij het aanbieden van haar diensten gebruik van cookies. Dit is een klein eenvoudig
        bestandje met gegevens dat of op de harde schijf van uw computer wordt opgeslagen of in de sessie van uw browser staat.
      </p>
      <p>
        De sessie-cookie bevat geen persoonsgegevens en dient enkel om het gebruik van de Autotaxatie Partners website voor u
        gemakkelijker te maken.
      </p>
      <p>
        Daarnaast maakt de Autotaxatie Partners ook gebruik van een speciaal cookie. Aan de hand van dit cookie kan onze
        website u herkennen de volgende keer dat u op de site langskomt. Met behulp van dit cookie kan de site speciaal
        op u worden ingesteld; een hulpmiddel om het gebruik van de site voor u nog aangenamer te maken. Als u in uw
        browser het gebruik van cookies heeft uitgezet, kunt u nog steeds de meeste onderdelen van de website bezoeken.
        Het kan echter voorkomen dat bepaalde diensten of elementen van de site niet of niet optimaal functioneren.
      </p>
      <br>
      <h3>Soorten cookies</h3>
      <p>
        Cookies welke de Autotaxatie Partners gebruikt zijn te verdelen in drie soorten:
      </p>
      <p>
      </p>
      <ol style="margin-left: 20px;">
        <li>1. Functionele cookies<br>Sommige cookies zijn onmisbaar voor een goede werking van onze website. Wij gebruiken
          deze cookies om uw voorkeuren te kunnen onthouden. Dit zijn bijvoorbeeld cookies die gebruikt worden om u nadat
          u ingelogd bent te kunnen blijven herkennen. Op deze manier hoeft u niet bij ieder bezoek opnieuw uw wachtwoord
          in te typen en weten we zeker dat u gerechtigd bent om besloten onderdelen van onze website te benaderen.</li>
        <li>2. Prestatie cookies<br>Wij gebruiken cookies onder andere om informatie te verzamelen. Met deze informatie
          zien we welke pagina's het meest bezocht worden, welke pagina's effectief zijn en welke pagina's foutmeldingen
          opleveren. Met deze informatie optimaliseren we het gebruiksgemak van de website.</li>
        <li>3. Marketing cookies<br>Autotaxatie Partners en onze adverteerders gebruiken van tijd tot tijd cookies om u
          te voorzien van advertenties waarvan wij denken dat deze relevant voor u zijn, op basis van eerder door u opgevraagde
          pagina's.</li>
      </ol>
      <p></p>
      <br>
      <h3>Blokkeren van cookies</h3>
      <p>
        Uiteraard is het ook mogelijk om cookies te blokkeren. De meeste internetbrowsers kunnen zodanig ingesteld worden,
        dat cookies niet geaccepteerd worden of dat u op de hoogte wordt gesteld, wanneer u een cookie ontvangt. De wijze
        waarop u dit kunt doen is afhankelijk van de browser die u gebruikt. U dient er rekening mee te houden dat bepaalde
        delen van de Autotaxatie Partners website niet of niet optimaal zullen functioneren wanneer u cookies blokkeert.
      </p>
      <br>
      <h3>Cookies achteraf verwijderen</h3>
      <p>
        Na het bezoek aan Autotaxatie Partners kunt u ervoor kiezen om de cookies van uw computer verwijderen. Hoe u dit
        kunt doen verschilt per browser.
        <br>
        <br>
        <br>
        <a name="privacy"></a>
      </p>
      <h1 style="font-size:20px;">Privacy Statement</h1>
      <p>
        Autotaxatie Partners gebruikt bepaalde persoonsgegevens van de gebruikers van de door haar beheerde websites.
        Autotaxatie Partners gebruikt die gegevens om de prestaties van die websites te verbeteren (bijvoorbeeld door
        deze beter op de wensen van de gebruikers aan te kunnen laten sluiten); om verzoeken van de gebruikers om informatie
        te kunnen verwerken en om (commerciële) activiteiten te kunnen ontplooien (bijvoorbeeld door relaties met de gebruikers
        van de websites tot stand te kunnen brengen en/of uit te breiden). Autotaxatie Partners gebruikt in alle gevallen
        alleen die persoonsgegevens die de gebruikers van de websites zelf aan Autotaxatie Partners ter beschikking stellen.
        De gebruikers stellen die gegevens (zoals browsertype en IP-adres) onder andere ter beschikking door hun browser
        cookies te laten accepteren en door zich op de websites te registreren. Autotaxatie Partners respecteert bij het
        gebruik van de persoonsgegevens van de websites de regels van de Wet bescherming persoonsgegevens. Autotaxatie Partners
        draagt er door die gegevens te beveiligen zorg voor dat die gegevens geheim gehouden worden. Autotaxatie Partners is
        niet in staat te garanderen dat de beheerders van de websites waarnaar de door haar beheerder websites links bevatten,
        de regels van de Wet bescherming persoonsgegevens respecteren. Autotaxatie Partners is voor alle vragen over het
        gebruik van persoonsgegevens van de gebruikers van de door haar beheerde websites bereikbaar via: inf&#64;autotaxatiepartners.nl.
      </p>
      <br>
      <br>
      <br>
      <a name="copyright"></a>
      <h1 style="font-size:20px;">Copyright</h1>
      <p>
        Niets uit de tekst of grafische voorstellingen in de websites mag zonder schriftelijke toestemming van Autotaxatie
        Partners B.V. worden verveelvoudigd en/of openbaar gemaakt door druk, fotokopie, fax, overtypen, opslag in een
        geautomatiseerd gegevensbestand of anderszins, hetgeen eveneens van toepassing is op een gehele of gedeeltelijke
        bewerking daarvan. De gegevens zijn met de grootst mogelijke zorgvuldigheid vastgelegd. Autotaxatie Partners B.V.
        of één van haar werkmaatschappijen is niet aansprakelijk te stellen voor onjuiste of onvolledige informatie die
        op deze internetpagina's vermeld staat. Er zijn aan deze gegevens geen rechten te ontlenen.
      </p>
      <a name="services"></a></div>
  </div>
</div>