<footer class="bg-dark text-white  py-2">
  <div class="container d-flex justify-content-between align-items-center">
      <div>
          <a href="voorwaarden" class="text-white text-decoration-none">Voorwaarden</a>
          <a href="tel:0888833222" class="text-white text-decoration-none ms-3">Support</a>
      </div>
      <div>
          <p class="mb-0">&copy; {{currentYear}} Autotaxatie Partners</p>
      </div>
  </div>
</footer>

