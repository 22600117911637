<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">Wachtwoord vergeten</h4>
        <div class="card-body">

            <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Gebruikersnaam</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && resetForm.controls['username'].errors }" />
                    <div *ngIf="submitted && resetForm.controls['username'].errors" class="invalid-feedback">
                        <div *ngIf="resetForm.controls['username'].hasError('required')">Gebruikersnaam is verplicht</div>
                    </div>
                </div>

                <div class="form-group">
                    <select *ngIf="loginTypes.length > 1" formControlName="login_type" class="form-control">
                        <option value="company">Bedrijf</option>
                        <option value="person">Persoon</option>
                    </select>
                </div>

                <div class="form-group">
                    <a routerLink="/login">Terug naar inloggen</a>
                </div>

                <button [disabled]="!resetForm.valid || loading" class="btn btn-primary reset-button">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Reset wachtwoord
                </button>
            </form>
        </div>
    </div>
</div>
