import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../Authentication/Modules/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-wachtwoord-wijzigen',
    templateUrl: 'wachtwoord_wijzigen.component.html',
    styleUrls: ['wachtwoord_wijzigen.component.css']
})

export class WachtwoordWijzigenComponent implements OnInit {
    tempPassword = false;
    error = false;
    public fullResponse: any = {};
    public account: any = {};
    public response: any;
    public barLabel = 'Sterkte wachtwoord:';
    public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    ngOnInit() {
    }

    updatePassword() {

    }
}
