<!-- Modal Template -->
  <div class="modal-header">
    <h5 class="modal-title">Zoek vergelijkbare voertuigen</h5>
  </div>
  <div class="modal-body">
    <div [@someCoolAnimation]="bindingVar">
      <ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true, primaryColour: '#63b5a2' }"></ngx-loading>
      <div class="col-md-12 nopadding">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <input type="text" minlength="4" class="form-control form-control-sm" [(ngModel)]="searchForm.taxnumber"
                id="taxnumber" placeholder="Taxatienummer">
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <input type="text" minlength="4" class="form-control form-control-sm" [(ngModel)]="searchForm.license"
                id="license" placeholder="Kenteken">
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <ng-select (ngModelChange)="vehicleTypesUpdate($event)" placeholder="Soort voertuig"
                [(ngModel)]="searchForm.vehicleType">
                <ng-option *ngFor="let type of VehicleTypes" [value]="type.id">{{ type.descriptionNl | capitalize
                  }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 nopadding">
        <div id="accordion" class="panel-group">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <ng-select placeholder="Merk" (ngModelChange)="makesUpdate($event)" [(ngModel)]="searchForm.make">
                  <ng-option *ngFor="let make of Makes" [value]="make.make">{{ make.make | capitalize }}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <ng-select placeholder="Model.." [(ngModel)]="searchForm.model">
                  <ng-option *ngFor="let model of Models" [value]="model.modelSpecific">{{ model.modelSpecific |
                    capitalize }}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <ng-select placeholder="Brandstof.." [(ngModel)]="searchForm.fuelType">
                  <ng-option *ngFor="let fuel of FuelTypes" [value]="fuel.id">{{ fuel.descriptionNl | capitalize
                    }}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <input type="number" class="form-control form-control-sm" id="formGroupExampleInput"
                  [(ngModel)]="searchForm.yearMin" placeholder="Bouwjaar (min)">
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <input type="number" class="form-control form-control-sm" id="formGroupExampleInput"
                  [(ngModel)]="searchForm.yearMax" placeholder="Bouwjaar (max)">
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <ng-select placeholder="Transmissie.." [(ngModel)]="searchForm.transmission">
                  <ng-option *ngFor="let transmission of TransmissionTypes" [value]="transmission.id">{{
                    transmission.descriptionNl | capitalize }}</ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <ng-select placeholder="Carrosserie.." [(ngModel)]="searchForm.bodyType">
                  <ng-option *ngFor="let body of BodyTypes" [value]="body.id">{{ body.descriptionNl | capitalize
                    }}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-check">
                <div class="form-group">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="searchForm.onlyInCurrentBidTerm">
                    Alleen zoeken in huidige biedtermijn
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-check">
                <div class="form-group">
                  <label class="form-check-label">
                    <input class="form-check-input" [(ngModel)]="searchForm.onlyMyVehicles" type="checkbox"> Alleen
                    zoeken in mijn voertuigen
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button style="background-color:#d65d8c;" (click)="search(1)" type="button" class="btn btn-sm btn-primary">Zoeken
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
      <br>

      <div id="result" *ngIf="SearchResults">
        <br>
        <h3>Vergelijkbare voertuigen</h3>
        <p>Er zijn <b>{{ TotalResults }}</b> vergelijkbare voertuigen gevonden.</p>
        <div id="searchResults" class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="hasOrdering" (click)="setOrder('id');">Taxatienr</th>
                <th>Kenteken</th>
                <th class="hasOrdering" (click)="setOrder('Vehicle.make');">Voertuig</th>
                <th class="hasOrdering" (click)="setOrder('Vehicle.year');">Bouwjaar</th>
                <th class="hasOrdering" (click)="setOrder('VehicleSpecification.mileage');">Tellerstand</th>
                <th>Leverdatum</th>
                <th>Mijn bod</th>
              </tr>
            </thead>
            <tbody *ngIf="!SearchResults">
              <tr>
                <td>Geen resultaten gevonden.</td>
                <td colspan="8"></td>
              </tr>
            </tbody>
            <tbody *ngIf="SearchResults">
              <tr *ngFor="let data of SearchResults" (click)="navigateToTax(data.id)">
                <td><b>T{{ data.id }}</b></td>
                <td>{{ data.VehicleSpecification.Vehicle.registration | licensePipe:
                  data.VehicleSpecification.Vehicle.registration }}</td>
                <td>{{ data.VehicleSpecification.Vehicle.make | capitalize }} {{
                  data.VehicleSpecification.Vehicle.modelSpecific | capitalize }}</td>
                <td>{{ data.VehicleSpecification.Vehicle.year }}</td>
                <td>{{ data.VehicleSpecification.mileage | number | replace:',':'.' }} {{
                  data.VehicleSpecification.mileageType }}</td>
                <td *ngIf="data.dateDelivery != null">{{ (data | dateDelivery).date | date: 'dd-MM-yyyy' }}</td>
                <td *ngIf="data.BidList.MyBid != null">€ {{ data.BidList.MyBid.bid | number:'1.0-0':'nl' }}</td>
                <td *ngIf="data.BidList.MyBid == null">Geen bod</td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav aria-label="Paginanummering">
          <ul class="pagination pagination-sm justify-content-center">
            <nav aria-label="Paginanummering">
              <ul class="pagination pagination-sm justify-content-center">
                <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                  <a class="page-link" (click)="setPage(1)">
                    <i class="fa fa-fast-backward" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="page-item" [ngClass]="{disabled:currentPage === 1}">
                  <a class="page-link" (click)="setPage(currentPage - 1)">
                    <i class="fa fa-backward" aria-hidden="true"></i>
                  </a>
                </li>
                <li style="color:#d65d8c;" class="page-item">
                  <div class="dropdown">
                    <button style="border: 1px solid #dee2e6;" class="btn btn-sm btn-default dropdown-toggle"
                      type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      Pagina {{currentPage}} van {{totalPages}}
                    </button>

                    <ul id="dropdown-menu" class="dropdown-menu" aria-labelledby="dropdownMenu1">
                      <li> <input (keyup.enter)="navigateToPage()" [(ngModel)]="setPageNumber" type="number"
                          class="form-control-sm form-control" placeholder="Ga naar pagina..."></li>
                    </ul>
                  </div>
                </li>

                <li class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
                  <a class="page-link" class="page-link" (click)="setPage(currentPage + 1)">
                    <i class="fa fa-forward" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
                  <a class="page-link" (click)="setPage(totalPages)">
                    <i class="fa fa-fast-forward" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button style="background-color:#d65d8c;" type="button" class="btn btn-primary"
      (click)="activeModal.close('Close click')">Sluiten</button>
  </div>