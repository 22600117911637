import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateDelivery'
})
export class DateDeliveryPipe implements PipeTransform {

  transform(valuationData: any, colorEnabled: boolean = false, thresholds: number[] = [30, 60, 90]): { date: string, color: string } {
    let date = 'Onbekend';
    let color = 'black';

    if (valuationData.dateDelivered) {
      date = valuationData.dateDelivered.date;
    } else if (valuationData.dateDelivery) {
      date = valuationData.dateDelivery.date;
    } else {
      return { date, color };
    }

    if (colorEnabled) {
      const numberOfDays = this.calNumberOfDays(date);

      if (numberOfDays < 0) {
        color = 'red';
      } else if (numberOfDays < thresholds[0]) {
        color = 'green';
      } else if (numberOfDays < thresholds[1]) {
        color = 'orange';
      } else if (numberOfDays < thresholds[2]) {
        color = 'red';
      } else {
        color = 'red';
      }
    }

    return { date, color };
  }

  private calNumberOfDays(date: string): number {
    const deliveryDate = moment(date);
    const today = moment();
    const daysDifference = deliveryDate.diff(today, 'days');
    return daysDifference;
  }
}
