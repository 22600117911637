<title>Expertteam - Zoeken</title>
<app-headermenu></app-headermenu>
<ngx-loading [show]="loading" [config]="{fullScreenBackdrop: true,  primaryColour:'#63b5a2'}"></ngx-loading>

<div class="container container-zoeken">
  <h3>Taxaties zoeken</h3>
  <hr>
  <form [formGroup]="searchForm" class="container-fluid">
    <div class="row mb-3">
      <div class="col-md-2">
        <div class="form-group">
          <input type="text" (keyup.enter)="search(1)" minlength="4" class="form-control form-control-sm"
            formControlName="taxnumber" id="taxnumber" placeholder="Taxatienummer">
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <input type="text" (keyup.enter)="search(1)" minlength="4" class="form-control form-control-sm"
            formControlName="vin" id="vin" placeholder="VIN nummer">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <input type="text" (keyup.enter)="search(1)" minlength="4" class="form-control form-control-sm"
            formControlName="license" id="license" placeholder="Kenteken">
        </div>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-md-4">
        <div class="form-group">
          <ng-select (change)="vehicleTypesUpdate($event)" [placeholder]="'Soort voertuig...'"
            formControlName="vehicleType">
            <ng-option *ngFor="let type of VehicleTypes" [value]="type.id">{{ type.descriptionNl | capitalize
              }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="accordion" id="vehicleAccordion">
      <div class="row mb-3">
        <div class="col-md-4">
          <div class="form-group">
            <ng-select [placeholder]="'Merk...'" (change)="makesUpdate($event)" formControlName="make">
              <ng-option *ngFor="let make of Makes" [value]="make.make">{{ make.make | capitalize }}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select [placeholder]="'Model...'" formControlName="model">
              <ng-option *ngFor="let model of Models" [value]="model.modelSpecific">{{ model.modelSpecific | capitalize
                }}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select [placeholder]="'Brandstof...'" formControlName="fuelType">
              <ng-option *ngFor="let fuel of FuelTypes" [value]="fuel.id">{{ fuel.descriptionNl | capitalize
                }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-2">
          <div class="form-group">
            <input type="number" class="form-control form-control-sm" formControlName="yearMin"
              placeholder="Bouwjaar (min)">
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <input type="number" class="form-control form-control-sm" formControlName="yearMax"
              placeholder="Bouwjaar (max)">
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select [placeholder]="'Transmissie...'" formControlName="transmission">
              <ng-option *ngFor="let transmission of TransmissionTypes" [value]="transmission.id">
                {{ transmission.descriptionNl | capitalize }}
              </ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <ng-select [placeholder]="'Carrosserie...'" formControlName="bodyType">
              <ng-option *ngFor="let body of BodyTypes" [value]="body.name">{{ body.descriptionNl | capitalize
                }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group form-check">
            <input class="form-check-input" type="checkbox" formControlName="onlyInCurrentBidTerm"
              id="onlyInCurrentBidTerm">
            <label class="form-check-label" for="onlyInCurrentBidTerm">
              Alleen zoeken in huidige biedtermijn
            </label>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group form-check">
            <input class="form-check-input" formControlName="onlyMyVehicles" type="checkbox" id="onlyMyVehicles">
            <label class="form-check-label" for="onlyMyVehicles">
              Alleen zoeken in mijn voertuigen
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-end mt-3">
    <button (click)="reset()" type="button" class="btn btn-outline-secondary me-2">
      Reset
    </button>

    <button (click)="isSearchingWithButton = true; search(1)" type="button" class="btn text-white"
      style="background-color: #d65d8c;">
      Zoeken
      <i class="fa fa-search ms-1" aria-hidden="true"></i>
    </button>
  </div>

  <br>

  <div id="result" *ngIf="SearchResults">
    <br>
    <h3>Zoekresultaten</h3>
    <p *ngIf="SearchResults">Uw zoekopdracht heeft
      <b>{{TotalResults}}</b> resultaten opgeleverd.
    </p>
    <div id="searchResults" class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="hasOrdering" (click)="setOrder('id');">Taxatienr
              <span *ngIf="currentOrder[0].field == 'id' && currentOrder[0].direction == 'ASC'"><i
                  class="fas fa-sort-down"></i></span>
              <span *ngIf="currentOrder[0].field == 'id' && currentOrder[0].direction == 'DESC'"><i
                  class="fas fa-sort-up"></i></span>
              <span *ngIf="currentOrder[0].field != 'id'"><i class="fas fa-sort"></i></span>
            </th>
            <th style="color:#d65d8c;">Kenteken</th>
            <th class="hasOrdering" (click)="setOrder('Vehicle.make');">Voertuig
              <span *ngIf="currentOrder[0].field == 'Vehicle.make' && currentOrder[0].direction == 'ASC'"><i
                  class="fas fa-sort-down"></i></span>
              <span *ngIf="currentOrder[0].field == 'Vehicle.make' && currentOrder[0].direction == 'DESC'"><i
                  class="fas fa-sort-up"></i></span>
              <span *ngIf="currentOrder[0].field != 'Vehicle.make'"><i class="fas fa-sort"></i></span>
            </th>
            <th class="hasOrdering" (click)="setOrder('Vehicle.year');">Bouwjaar
              <span *ngIf="currentOrder[0].field == 'Vehicle.year' && currentOrder[0].direction == 'ASC'"><i
                  class="fas fa-sort-down"></i></span>
              <span *ngIf="currentOrder[0].field == 'Vehicle.year' && currentOrder[0].direction == 'DESC'"><i
                  class="fas fa-sort-up"></i></span>
              <span *ngIf="currentOrder[0].field != 'Vehicle.year'"><i class="fas fa-sort"></i></span>
            </th>
            <th class="hasOrdering" (click)="setOrder('VehicleSpecification.mileage');">Tellerstand
              <span
                *ngIf="currentOrder[0].field == 'VehicleSpecification.mileage' && currentOrder[0].direction == 'ASC'"><i
                  class="fas fa-sort-down"></i></span>
              <span
                *ngIf="currentOrder[0].field == 'VehicleSpecification.mileage' && currentOrder[0].direction == 'DESC'"><i
                  class="fas fa-sort-up"></i></span>
              <span *ngIf="currentOrder[0].field != 'VehicleSpecification.mileage'"><i class="fas fa-sort"></i></span>
            </th>
            <th style="color:#d65d8c;">Leverdatum</th>
            <th style="color:#d65d8c;">Mijn bod</th>
            <th style="color:#d65d8c;">Hoogste bod</th>
          </tr>
        </thead>

        <tbody *ngIf="SearchResults == ''">
          <tr>
            <td>Geen resultaten gevonden.</td>
            <td colspan="8"></td>
          </tr>
        </tbody>

        <tbody *ngIf="SearchResults">
          <tr *ngFor="let data of SearchResults" (click)="navigateToDetail(data.id)">
            <td>
              <b>T{{data.id}}</b>
            </td>
            <td>{{data.VehicleSpecification.Vehicle.registration | licensePipe:
              data.VehicleSpecification.Vehicle.registration}}</td>
            <td>{{data.VehicleSpecification.Vehicle.make | capitalize}}
              {{data.VehicleSpecification.Vehicle.modelSpecific |
              capitalize}}</td>
            <td>{{data.VehicleSpecification.Vehicle.year}}</td>
            <td>{{data.VehicleSpecification.mileage | number | replace:',':'.'}}
              {{data.VehicleSpecification.mileageType}}</td>
            <td *ngIf="data.dateDelivery != null">{{(data | dateDelivery).date | date: 'dd-MM-yyyy'}}</td>

            <td *ngIf="data.BidList.MyBid != null">€ {{data.BidList.MyBid.bid | number:'2.0-0':'nl'}}</td>
            <td *ngIf="data.BidList.MyBid == null">Geen bod</td>

            <td *ngIf="data.highestExpertBid != null">€ {{data.highestExpertBid | number:'2.0-0':'nl'}}</td>
            <td *ngIf="data.highestExpertBid == null">Geen bod</td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Paginanummering">
      <ul class="pagination pagination-sm justify-content-center">
        <li style="color:#d65d8c;" class="page-item" [ngClass]="{disabled:currentPage === 1}">
          <a class="page-link" (click)="setPage(1)">
            <i class="fa fa-fast-backward" aria-hidden="true"></i>
          </a>
        </li>
        <li style="color:#d65d8c;" class="page-item" [ngClass]="{disabled:currentPage === 1}">
          <a class="page-link" (click)="setPage(currentPage - 1)">
            <i class="fa fa-backward" aria-hidden="true"></i>
          </a>
        </li>

        <li style="color:#d65d8c;" class="page-item">
          <div class="dropdown">
            <button style="border: 1px solid #dee2e6;" class="btn btn-sm btn-default dropdown-toggle" type="button"
              id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              Pagina {{currentPage}} van {{totalPages}}
            </button>

            <ul id="dropdown-menu" class="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li> <input (keyup.enter)="navigateToPage()" type="number" class="form-control-sm form-control"
                  placeholder="Ga naar pagina..."></li>
            </ul>
          </div>
        </li>

        <li style="color:#d65d8c;" class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
          <a class="page-link" class="page-link" (click)="setPage(currentPage + 1)">
            <i class="fa fa-forward" aria-hidden="true"></i>
          </a>
        </li>
        <li style="color:#d65d8c;" class="page-item" [ngClass]="{disabled:currentPage === totalPages}">
          <a class="page-link" (click)="setPage(totalPages)">
            <i class="fa fa-fast-forward" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>