<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">Wachtwoord wijzigen</h4>
        <div class="card-body">
            <div *ngIf="success" class="alert alert-success mt-3 mb-0">Wachtwoord gewijzigd! Log opnieuw in.</div>
            <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage | json}}</div><br>

            <div *ngIf="success" class="form-group">
                <a routerLink="/login">Terug naar login</a>
            </div>

            <form *ngIf="!success" [formGroup]="passwordChangeForm" (ngSubmit)="onSubmit()">
                <div *ngIf="!tempPassword" class="form-group">
                    <label for="old_password">Huidig wachtwoord</label>
                    <input type="password" formControlName="old_password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }" />
                    <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                        <div *ngIf="f.old_password.errors.required">Wachtwoord is verplicht</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="new_password">Nieuw wachtwoord</label>
                    <input placeholder="Voer een nieuw wachtwoord in" type="password" formControlName="new_password"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" />
                    <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                        <div *ngIf="f.new_password.errors.required">Nieuw wachtwoord is verplicht</div>
                    </div>

                    <mat-password-strength #passwordComponent
                        [password]="passwordChangeForm.controls['new_password'].value">
                    </mat-password-strength>
                </div>

                <div class="form-group">
                    <label for="new_confirm_password">Nieuw wachtwoord bevestigen</label>
                    <input placeholder="Bevestig het nieuwe wachtwoord" type="password"
                        formControlName="new_confirm_password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.new_confirm_password.errors }" />
                    <div *ngIf="submitted && f.new_confirm_password.errors" class="invalid-feedback">
                        <div *ngIf="f.new_confirm_password.errors.required">Nieuw wachtwoord herhalen is verplicht</div>
                    </div>
                </div>

                <p>Zorg ervoor dat het nieuwe wachtwoord aan onderstaande eisen voldoet:</p>
                <mat-password-strength-info [passwordComponent]="passwordComponent" [enableScoreInfo]="false"
                    lowerCaseCriteriaMsg="Kleine letters (minimaal 1)" upperCaseCriteriaMsg="Hoofdletters (minimaal 1)"
                    digitsCriteriaMsg="Numerieke tekens (minimaal 1)"
                    specialCharsCriteriaMsg="Speciale tekens (minimaal 1)"
                    minCharsCriteriaMsg="Lengte (minimaal 8 tekens)">
                </mat-password-strength-info>

              <div class="mt-4">
                <button [disabled]="loading || !passwordChangeForm.valid" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Wachtwoord wijzigen
                </button>
              </div>
            </form>
        </div>
    </div>
</div>