<title>Autotaxatie Partners | Expertteam - login</title>

<ngx-ui-loader fgsColor="#d65d8c"></ngx-ui-loader>

<div class="col-md-6 offset-md-3 mt-5">
    <div class="card">
        <h4 class="card-header">Selecteer een bedrijf</h4>
        <div class="card-body">
            <p>Selecteer een bedrijf uit de onderstaande lijst om in te loggen.</p>
            <div class="list-group">
                <a (click)="selectCompany(company)" *ngFor="let company of userInformation.Companies" class="list-group-item list-group-item-action clickable">{{company.name}}</a>
            </div>

            <button (click)="logout()" type="button" class="btn btn-secondary btn-block mt-4">Annuleren</button>
        </div>
    </div>
</div>